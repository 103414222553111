import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const ErrorCircle: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const Rect = useComponent('rect')

  return (
    <Svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect width={28} height={28} rx={14} fill="#FB5555" />
      <Path
        d="m9.352 9.455 9.193 9.193m-9.397-.307 9.193-9.193"
        stroke="#FDFDFD"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </Svg>
  )
}

export default ErrorCircle
