import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Animated,
  Clipboard,
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
// import QRCode from "react-native-qrcode-svg"; can render logo, check if works in React Web
import UniversalQRCode from 'react-qr-code'
import { currency, toNumber, trimUid } from '@cryptoswift/shared/src//utils/formatters'
import { CreateSaleInput, StatusEnum } from '@cryptoswift/shared/src/__generated__/globalTypes'
import CopyText from '@cryptoswift/shared/src/components/icons/Utility/CopyText'
import {
  CreateInPersonSale,
  CreateInPersonSaleVariables
} from '@cryptoswift/shared/src/gql/mutations/__generated__/CreateInPersonSale'
import { CREATE_IN_PERSON_SALE } from '@cryptoswift/shared/src/gql/mutations/createInPersonSale'
import { useCurrency } from '@cryptoswift/shared/src/hooks/api/useCurrency'
import { useSale } from '@cryptoswift/shared/src/hooks/api/useSale'
import useAnimatedAppear from '@cryptoswift/shared/src/hooks/utils/useAnimatedAppear'
import { BACKGROUNDS, COLORS, FONTS } from '@cryptoswift/shared/src/styles'
import { LINKS_GENERATOR } from '@cryptoswift/shared/src/utils/qr'

import { useMutation } from '@apollo/client'

import Button from '../../../components/controls/Button'
import LogoBlue from '../../../components/icons/LogoBlue'
import ArrowRoundDown from '../../../components/icons/Utility/ArrowRoundDown'
import CheckSuccess from '../../../components/icons/Utility/CheckSuccess'
import CopyBlackLarge from '../../../components/icons/Utility/CopyBlackLarge'
import ErrorCircle from '../../../components/icons/Utility/ErrorCircle'
import ExclamationError from '../../../components/icons/Utility/ExclamationError'
import ExclamationErrorWarning from '../../../components/icons/Utility/ExclamationErrorWarning'
import BackdropModal from '../../../components/Modal'
import Paper from '../../../components/Paper'
import {
  CancelInPersonSale,
  CancelInPersonSaleVariables
} from '../../../gql/mutations/__generated__/CancelInPersonSale'
import { RestartSale, RestartSaleVariables } from '../../../gql/mutations/__generated__/RestartSale'
import { StartInPersonSale, StartInPersonSaleVariables } from '../../../gql/mutations/__generated__/StartInPersonSale'
import { CANCEL_IN_PERSON_SALE } from '../../../gql/mutations/cancelInPersonSale'
import { RESTART_SALE } from '../../../gql/mutations/restartSale'
import { START_IN_PERSON_SALE } from '../../../gql/mutations/startInPersonSale'
import { analyzeHTMLContent, useReadMore } from '../../../hooks/utils/useReadMore'
import { useComponent } from '../../../providers/Components'

import BlurrySrc from './blurry.png'
import Countdown from './Countdown'
import PaymentProcessStep, { ProcessStepStatus } from './PaymentProcessStep'
import StatusesList from './StatusesList'

const PaymentProcess: FC<{
  data: CreateSaleInput
  existingSaleId?: string
  kyc?: string | null
  redirect?: () => void
  children: ReactNode
  logEvent?: (sale: Record<any, any>) => void
  disableCreateNew?: boolean
  env?: string
}> = ({ env = 'beta', data, existingSaleId, kyc = null, redirect, children, logEvent, disableCreateNew = false }) => {
  const animatedAppearStyles = useAnimatedAppear()
  const [saleId, setSaleId] = useState<string | undefined>(existingSaleId ?? undefined)
  const { result: saleData, refetch } = useSale(saleId)
  const { findOne } = useCurrency()
  const currentCrypto = findOne(saleData?.cryptoCurrencyId ?? undefined)
  const [showPaidStatusModal, setShowPaidStatusModal] = useState<boolean>(false)

  const isBalanceChanged = [
    saleData?.cryptoCurrencyVolume ?? 0,
    Math.abs(Number(saleData?.amountToPay ?? 0)),
    saleData?.paidAmount ?? 0
  ].every(v => Number(v) > 0)

  const paymentDiff = Math.abs(Number(saleData?.amountToPay ?? 0))
  const isUnderpaid = isBalanceChanged ? Number(saleData?.amountToPay ?? 0) > 0 : false
  const isOverpaid = isBalanceChanged ? Number(saleData?.amountToPay ?? 0) < 0 : false

  const modalPrefix = isUnderpaid ? 'underpaid' : 'overpaid'

  useEffect(() => {
    const detectPaymentStatus = () => {
      if (isBalanceChanged) {
        if (paymentDiff !== 0) {
          setShowPaidStatusModal(true)
        }
      }
    }

    detectPaymentStatus()
  }, [isBalanceChanged, paymentDiff])

  // *** TEMP SOLUTION, REMOVE WHEN WS WORK ***
  useEffect(() => {
    const it = setInterval(() => {
      refetch().then(d => {
        const payload = d.data.sale

        logEvent?.({
          saleId: payload.sale?.uid,
          saleStatus: payload.sale?.status
        })

        if (
          payload.sale?.status &&
          [StatusEnum.ERROR, StatusEnum.EXPIRED, StatusEnum.CANCELED, StatusEnum.COMPLETED].includes(
            payload.sale.status
          )
        ) {
          clearInterval(it)
        }
      })
    }, 3000)

    return () => {
      clearInterval(it)
    }
  }, [refetch])
  // *** TEMP SOLUTION, REMOVE WHEN WS WORK ***

  const isExpired = saleData?.status === StatusEnum.EXPIRED || saleData?.status == StatusEnum.CANCELED
  const isPaid = saleData?.status && !isExpired && (saleData.isFullyPaid || saleData?.status === StatusEnum.ERROR)

  const [startSale, { error: startSaleError }] = useMutation<StartInPersonSale, StartInPersonSaleVariables>(
    START_IN_PERSON_SALE,
    {
      onCompleted: createdSale => {
        logEvent?.({
          saleId: createdSale.startSale.sale?.uid,
          saleStatus: createdSale.startSale.sale?.status
        })
        refetch()
      },
      onError: e => {
        console.log('startSale', e)
      }
    }
  )

  const [createInPersonSale, { error: saleError }] = useMutation<CreateInPersonSale, CreateInPersonSaleVariables>(
    CREATE_IN_PERSON_SALE,
    {
      onCompleted: result => {
        if (result.createSale.sale?.uid) {
          logEvent?.({
            saleId: result.createSale.sale?.uid,
            saleStatus: result.createSale.sale?.status
          })
          startSale({
            variables: {
              uid: result.createSale.sale.uid
            }
          })
          setSaleId(result.createSale.sale.uid)
        }
      }
    }
  )

  const createSaleError = saleError || startSaleError

  const createNewSale = useCallback(() => {
    const payload = {
      ...data,
      customerEmail: data?.customerEmail?.trim() ?? null,
      cryptoCurrencyId: Number(data.cryptoCurrencyId),
      isKycRequired: Boolean(kyc),
      customerPhone: data?.customerPhone || '',
      kycId: kyc
    }

    createInPersonSale({
      variables: { data: payload }
    }).catch(e => {
      console.log('>> error sale create', e, payload)
    })
  }, [])

  useEffect(() => {
    if (!saleId && !existingSaleId) {
      createNewSale()
    }
  }, [saleId, existingSaleId])

  const url =
    saleData?.proxyWalletAddress && currentCrypto?.blockchain
      ? LINKS_GENERATOR[currentCrypto.blockchain!](
          saleData.proxyWalletAddress!,
          Math.abs(toNumber(saleData?.amountToPay ?? '0')),
          data.description
        )
      : ''

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const qrCode = Boolean(url) && <UniversalQRCode value={url} level={'H'} size={180} />

  const opacity1 = useRef(new Animated.Value(1)).current
  const opacity2 = useRef(new Animated.Value(0)).current
  const translateX1 = useRef(new Animated.Value(0)).current
  const translateX2 = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (isPaid) {
      Animated.parallel([
        Animated.timing(opacity1, {
          toValue: 0,
          duration: 250,
          useNativeDriver: false
        }),
        Animated.timing(opacity2, {
          toValue: 1,
          duration: 250,
          useNativeDriver: false
        }),
        Animated.timing(translateX1, {
          toValue: 640,
          duration: 250,
          useNativeDriver: false
        }),
        Animated.timing(translateX2, {
          toValue: -248,
          duration: 250,
          useNativeDriver: false
        })
      ]).start()
    }
  }, [isPaid])

  const setInitialView = useCallback(() => {
    Animated.parallel([
      Animated.timing(opacity1, {
        toValue: 1,
        duration: 250,
        useNativeDriver: false
      }),
      Animated.timing(opacity2, {
        toValue: 0,
        duration: 250,
        useNativeDriver: false
      }),
      Animated.timing(translateX1, {
        toValue: 0,
        duration: 250,
        useNativeDriver: false
      }),
      Animated.timing(translateX2, {
        toValue: 0,
        duration: 250,
        useNativeDriver: false
      })
    ]).start()
  }, [])

  useEffect(() => {
    if (isExpired) {
      setInitialView()
    }
  }, [isExpired])

  const qrCodeExpired = <Image style={styles.blurry} source={BlurrySrc as ImageSourcePropType} />
  const canceledSaleUid = useRef<string | undefined | null>(existingSaleId || null)
  const { t } = useTranslation()
  const [cancelSale] = useMutation<CancelInPersonSale, CancelInPersonSaleVariables>(CANCEL_IN_PERSON_SALE)
  const { onViewLayout, onTextLayout, allowReadMore, isOpen, setIsOpen, textStyle, Container } = useReadMore()
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const ImagePreview = useComponent('imagePreview')
  const [restartSale, { loading: isRestartingSale }] = useMutation<RestartSale, RestartSaleVariables>(RESTART_SALE)
  const [previewURL, setPreviewURL] = useState<Record<string, any> | null>()

  return (
    <>
      <ImagePreview visible={Boolean(previewURL)} source={previewURL} close={() => setPreviewURL(null)} />

      <BackdropModal visible={showPaidStatusModal} onRequestClose={() => setShowPaidStatusModal(false)}>
        <Paper
          style={{
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 32,
            minWidth: 510,
            width: 510
          }}
          animate
          aria-label="notofication-modal"
        >
          <View>
            <View style={styles.row}>
              <View style={{ marginRight: 4 }}>
                {isOverpaid && <ExclamationErrorWarning />}
                {isUnderpaid && <ExclamationError />}
              </View>

              <Text style={styles.text}>{t(`common:checkout.${modalPrefix}ModalTitle`)}</Text>
            </View>

            <View style={{ marginVertical: 12, flexDirection: 'column' }}>
              <Text style={FONTS.h4}>
                <Trans
                  i18nKey={`common:checkout.${modalPrefix}ModalDescription`}
                  components={{ b: <Text style={styles.bold} /> }}
                  values={{
                    diff: String(saleData?.amountToPay ?? 0).replace('-', ''),
                    email: `support@${env}.cryptoswift.net`,
                    full: String(saleData?.amountToPay ?? 0),
                    paid: String(saleData?.paidAmount ?? 0),
                    cryptoCurrency: findOne(saleData?.cryptoCurrencyId ?? undefined)?.name ?? ''
                  }}
                />
              </Text>
            </View>

            <View style={{ marginTop: 24 }}>
              <Button onClick={() => setShowPaidStatusModal(false)}>
                <Text>{t('common:hedgingAttention.continue')}</Text>
              </Button>
            </View>
          </View>
        </Paper>
      </BackdropModal>

      <View style={[styles.screenControls, Platform.OS === 'web' && { top: -70, right: 0, display: 'none' }]}>
        {!createSaleError && saleData?.status === StatusEnum.PENDING && !saleData?.isPaymentFound && (
          <Button
            light
            onClick={() => {
              cancelSale({
                variables: {
                  uid: saleId!
                }
              }).then(() => {
                canceledSaleUid.current = saleId!

                return refetch()
              })
            }}
            style={{ maxWidth: 110, marginRight: 8 }}
          >
            {t('common:checkout.cancel')}
          </Button>
        )}
        {!disableCreateNew && (isExpired || saleData?.status === StatusEnum.CANCELED) && (
          <Button
            isLoading={isRestartingSale}
            onClick={() => {
              if (canceledSaleUid?.current) {
                restartSale({
                  variables: {
                    uid: canceledSaleUid.current!
                  }
                }).then(responseData => {
                  const uid = responseData?.data?.restartSale?.sale?.uid

                  if (uid) {
                    setSaleId(uid)
                  }

                  setInitialView()
                })
              }
            }}
            style={{ maxWidth: 300, marginRight: 8 }}
          >
            {t('common:checkout.generateAnotherCode')}
          </Button>
        )}
        {saleData && Boolean(saleData?.status) && saleData.status !== StatusEnum.PENDING && (
          <>
            {Platform.OS !== 'web' && (
              <Button onClick={() => redirect?.()} style={{ maxWidth: 200 }}>
                {t('common:checkout.returnToHome')}
              </Button>
            )}
          </>
        )}
      </View>

      <>
        {Boolean(createSaleError) && (
          <Paper
            style={{
              paddingBottom: 32,
              paddingLeft: 32,
              paddingRight: 32,
              paddingTop: 32,
              minWidth: 510,
              width: 510
            }}
          >
            <View>
              <View style={[styles.row, { marginBottom: 20 }]}>
                <ExclamationError />
                <Text style={[styles.text, { marginLeft: 4 }]}>{t('common:checkout.createSaleError')}</Text>
              </View>
              <Text style={styles.description}>{createSaleError?.message}</Text>
            </View>
          </Paper>
        )}

        {!createSaleError && (
          <Animated.View style={[styles.root, animatedAppearStyles]} aria-label="payment-process-paper">
            <Animated.View
              style={[
                !isPaid && styles.qrSection,
                isPaid && styles.statusSection,
                styles.card,
                { transform: [{ translateX: translateX1 }] }
              ]}
              aria-label={Platform.OS === 'web' && isPaid && !isExpired ? 'payment-process-status' : undefined}
            >
              <Animated.View style={[isPaid && { display: 'none' }, { opacity: opacity1 }]}>
                <Text style={styles.qrSectionTitle}>{t('common:checkout.scanQrCode')}</Text>

                <View style={[styles.qrCode]}>{isExpired ? qrCodeExpired : qrCode}</View>

                <Countdown refetch={refetch} expiresAt={saleData?.paymentProcessExpirationDate}>
                  {({ timeLeft, total }) => (
                    <Text style={[styles.expires, saleData && !isExpired && total < 60 && { color: COLORS.error }]}>
                      {isExpired ? t('common:checkout.expired') : `${t('common:checkout.expiresIn')} ${timeLeft}`}
                    </Text>
                  )}
                </Countdown>

                <View style={styles.crossed}>
                  <View style={styles.crossedHr} />
                  <View>
                    <Text style={styles.crossedText}>{t('common:checkout.useAddressBelow')}</Text>
                  </View>
                  <View style={styles.crossedHr} />
                </View>

                <View>
                  <View style={[styles.item, { width: '100%' }]}>
                    <Text style={[styles.label, { width: '100%' }]}>
                      {t('common:checkout.address')} &nbsp;&nbsp;&nbsp;
                      {Boolean(saleData?.proxyWalletAddress) && !isExpired && (
                        <TouchableOpacity onPress={() => Clipboard.setString(saleData?.proxyWalletAddress ?? '')}>
                          <CopyText fill={COLORS.primaryViolet} />
                        </TouchableOpacity>
                      )}
                    </Text>
                    <Text style={[styles.text, { width: '100%' }]} aria-label="address">
                      {isExpired ? ' – ' : saleData?.proxyWalletAddress ?? ' – '}
                    </Text>
                  </View>
                </View>
              </Animated.View>

              <Animated.View style={[styles.statusesBox, !isPaid && { display: 'none' }, { opacity: opacity2 }]}>
                <View style={styles.statusesList}>
                  <Text style={styles.setupSection}>{t('common:checkout.currentStatus')}</Text>
                  {saleData?.status === StatusEnum.CANCELED && (
                    <View style={styles.expired}>
                      <ErrorCircle />
                      <View style={{ marginLeft: 8 }}>
                        <Text style={[styles.expiredTitle, { color: COLORS.error }]}>
                          {t('common:checkout.cancelled')}
                        </Text>
                      </View>
                    </View>
                  )}
                  {saleData?.status !== StatusEnum.CANCELED && saleData?.status !== StatusEnum.ERROR && (
                    <StatusesList data={saleData} currentCrypto={currentCrypto} />
                  )}

                  {saleData?.status === StatusEnum.ERROR && (
                    <View>
                      <View style={styles.expired}>
                        <ErrorCircle />
                        <View style={{ marginLeft: 12 }}>
                          <Text style={styles.expiredDescription}>{t('common:checkout.transactionStopped')}</Text>
                        </View>
                      </View>
                      <View style={styles.hr} />
                      <View style={{ marginVertical: 24 }}>
                        <View>
                          <ExclamationError />
                          <View style={{ marginTop: 12 }}>
                            <Text style={FONTS.textRegular}>{t(`common:saleApiErrors.${saleData?.errorType}`)}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </View>

                {saleData?.status === StatusEnum.COMPLETED && (
                  <>
                    <View style={styles.paymentCompletedHR} />
                    <View style={styles.paymentCompleted}>
                      <CheckSuccess />
                      <Text style={styles.paymentCompletedText}>{t('common:checkout.paymentConfirmed')}</Text>
                    </View>
                  </>
                )}
              </Animated.View>
            </Animated.View>
            <Animated.View
              style={[
                styles.contentSection,
                isPaid && { width: 640 },
                styles.card,
                isPaid && { width: 640 },
                { transform: [{ translateX: translateX2 }] }
              ]}
              aria-label="payment-process-details"
            >
              <View style={{ flex: 1 }}>
                <View>
                  {Boolean(saleData) && saleData?.status === StatusEnum.PENDING && !saleData?.isFullyPaid && (
                    <PaymentProcessStep
                      title={`Waiting for ${currentCrypto?.name}`}
                      status={ProcessStepStatus.Pending}
                    />
                  )}
                  {isExpired && (
                    <View style={styles.expired}>
                      <ErrorCircle />
                      <View style={{ marginLeft: 12 }}>
                        <Text style={styles.expiredTitle}>{t('common:checkout.saleExpired')}</Text>
                        <Text style={styles.expiredDescription}>{t('common:checkout.transactionStopped')}</Text>
                      </View>
                    </View>
                  )}
                </View>
                <Text style={styles.contentSectionTitle}>{t('common:checkout.saleInformation')}</Text>
                <ScrollView style={{ maxHeight: 480 }}>
                  <View style={{ marginTop: 16 }}>
                    <View style={styles.row}>
                      <View style={[styles.item, { width: '100%' }]}>
                        <Text style={styles.label}>{t('common:checkout.description')}</Text>
                        <Container
                          style={{ maxHeight: 280, width: '100%' }}
                          onLayout={onViewLayout}
                          aria-label={isOpen && 'full-height'}
                        >
                          <Text
                            ref={instance => {
                              if (instance && Platform.OS === 'web') {
                                const el = instance as any // HTMLElement
                                const { fontSize, fontFamily } = getComputedStyle(el)
                                const r = analyzeHTMLContent(el.innerHTML as string, fontFamily, fontSize) as any

                                onTextLayout(r)
                              }
                            }}
                            onTextLayout={onTextLayout}
                            style={[styles.text, textStyle]}
                            ellipsizeMode="tail"
                            numberOfLines={!isOpen ? 1 : undefined}
                          >
                            {`${(data?.description ?? ' – ').replace(!isOpen ? /\n/g : ' ', ' ')}`}
                          </Text>
                        </Container>
                        {allowReadMore && (
                          <Pressable
                            onPress={() => {
                              setIsOpen(v => !v)
                            }}
                          >
                            <Text style={styles.collapseText}>
                              {t(`common:textCollapse.${isOpen ? 'showLess' : 'showMore'}`)}
                            </Text>
                          </Pressable>
                        )}
                      </View>
                    </View>

                    {Boolean(data?.urlsToFiles) && (
                      <View style={{ flexDirection: 'row' }}>
                        {data?.urlsToFiles?.map((fileUrl, indexFile) => {
                          const source = { uri: fileUrl }

                          return (
                            <TouchableOpacity
                              key={indexFile}
                              style={{ marginBottom: 16, marginRight: 16 }}
                              onPress={() => setPreviewURL(source)}
                            >
                              <Image style={[styles.imagePreview]} source={source} />
                            </TouchableOpacity>
                          )
                        })}
                      </View>
                    )}

                    <View style={styles.row}>
                      <View style={styles.item}>
                        <Text style={styles.label}>{t('common:checkout.clientName')}</Text>
                        <Text style={styles.text}>{data?.customerName ?? ' – '}</Text>
                      </View>
                    </View>

                    <View style={styles.row}>
                      <Pressable
                        onPress={() => {
                          setIsDetailsOpen(v => !v)
                        }}
                      >
                        <Text style={styles.itemDescription}>
                          {t(isDetailsOpen ? 'common:checkout.showLess' : 'common:checkout.showMore')}
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          isDetailsOpen && {
                            transform: [
                              {
                                rotate: '180deg'
                              },
                              {
                                translateY: 16
                              }
                            ]
                          }
                        ]}
                        onPress={() => {
                          setIsDetailsOpen(v => !v)
                        }}
                      >
                        <ArrowRoundDown transparent />
                      </Pressable>
                    </View>

                    {isDetailsOpen && (
                      <>
                        <View style={styles.row}>
                          <View style={styles.item}>
                            <Text style={styles.label}>{t('common:checkout.saleId')}</Text>
                            <TouchableOpacity
                              style={{ flexDirection: 'row' }}
                              onPress={() => Clipboard.setString(saleData?.uid ?? '')}
                            >
                              <Text style={styles.text}>{trimUid(saleData?.uid)}</Text>
                              <View style={{ transform: [{ translateY: 8 }] }}>
                                <CopyText fill={COLORS.primaryViolet} />
                              </View>
                            </TouchableOpacity>
                          </View>
                          {Boolean(saleData?.customerPhone) && (
                            <View style={styles.item}>
                              <Text style={styles.label}>{t('common:checkout.phone')}</Text>
                              <Text style={styles.text}>
                                {saleData?.customerPhone ? saleData.customerPhone : ' – '}
                              </Text>
                            </View>
                          )}
                        </View>

                        <View style={styles.row}>
                          <View style={[styles.item, { width: '100%' }]}>
                            <Text style={styles.label}>{t('common:checkout.receiptSentTo')}</Text>
                            <Text style={styles.text}>{data?.customerEmail || ' – '}</Text>
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                </ScrollView>
              </View>

              <View style={styles.hr} />

              <View style={styles.total} aria-label="sale-total">
                <Text style={styles.totalText}>{t('common:checkout.totalPayment')}</Text>
                <View>
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Text style={styles.totalText}>
                      {isUnderpaid ? saleData?.amountToPay ?? '' : saleData?.cryptoCurrencyVolume ?? ''}{' '}
                      {findOne(saleData?.cryptoCurrencyId ?? undefined)?.name ?? ''}
                    </Text>
                    <TouchableOpacity
                      style={{ marginLeft: 12 }}
                      onPress={() => Clipboard.setString(String(saleData?.cryptoCurrencyVolume ?? '') ?? '')}
                    >
                      <CopyBlackLarge />
                    </TouchableOpacity>
                  </View>

                  <Text style={styles.totalTextFiat}>
                    {saleData?.fiatCurrencyVolume
                      ? currency(saleData.fiatCurrencyVolume, findOne(saleData?.fiatCurrencyId)?.symbol, false)
                      : ' – '}{' '}
                    {findOne(saleData?.fiatCurrencyId)?.name ?? ''}
                  </Text>

                  <Text style={styles.exchangeExample}>
                    <Text style={styles.asterisk}>*&nbsp;</Text>1 {currentCrypto?.name}
                    {' ≈ '}
                    {saleData ? saleData.rate : '–'} {findOne(saleData?.fiatCurrencyId)?.name ?? ''}
                  </Text>
                </View>
              </View>
            </Animated.View>

            {Boolean(children) && (
              <View style={styles.branding} aria-label="branding">
                {children}
              </View>
            )}
            <View style={styles.poweredBy} aria-label="powered-by">
              <Text style={styles.poweredByLabel}>{t('common:poweredBy')}</Text>
              <View style={styles.reducedLogo}>
                <LogoBlue />
              </View>
              <Text style={styles.poweredByCompany}>{t('common:appTitle')}</Text>
            </View>
          </Animated.View>
        )}
      </>
    </>
  )
}

export default PaymentProcess

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    maxWidth: 890,
    ...(Platform.OS === 'android' && {
      marginTop: 24
    })
  },
  card: {
    borderRadius: 2,
    borderColor: '#D9DBE8',
    borderWidth: 1,
    borderStyle: 'solid'
  },
  qrSection: {
    width: 346,
    backgroundColor: BACKGROUNDS.bg3,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16
  },
  qrSectionTitle: {
    ...FONTS.h2
  },
  contentSectionTitle: {
    ...FONTS.h2
  },
  contentSection: {
    backgroundColor: BACKGROUNDS.bg4,
    width: 540,
    paddingTop: 20,
    paddingRight: 40,
    paddingLeft: 24,
    paddingBottom: 24,
    justifyContent: 'space-between'
  },
  statusSection: {
    width: 248,
    backgroundColor: BACKGROUNDS.bg3,
    paddingVertical: 20,
    paddingHorizontal: 24,
    position: 'relative',
    zIndex: 1
  },
  hr: {
    borderColor: '#E7ECF3',
    borderBottomWidth: 1
  },
  row: {
    flexDirection: 'row'
  },
  item: {
    width: '50%',
    marginBottom: 16
  },
  label: {
    ...FONTS.textRegular,
    color: COLORS.primaryViolet
  },
  text: {
    ...FONTS.h4,
    color: COLORS.primaryBlack,
    marginTop: 4
  },
  total: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16
  },
  totalText: {
    ...FONTS.h3,
    color: COLORS.primaryBlack,
    textAlign: 'right'
  },
  expires: {
    ...FONTS.h3,
    textAlign: 'center',
    marginTop: 16
  },
  crossed: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 24
  },
  crossedHr: {
    flex: 1,
    borderColor: '#B1B5C3',
    borderBottomWidth: 1
  },
  crossedText: {
    ...FONTS.textRegular,
    minWidth: 194,
    color: COLORS.primaryViolet,
    textAlign: 'center'
  },
  qrCode: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
    minHeight: 180
  },
  asterisk: {
    color: COLORS.primaryBlack,
    fontSize: 16,
    paddingRight: 8
  },
  exchangeExample: {
    ...FONTS.textMedium,
    color: COLORS.primaryViolet,
    fontSize: 16,
    textAlign: 'right'
  },
  setupSection: {
    ...FONTS.textMedium,
    textTransform: 'uppercase',
    marginBottom: 24
  },
  expired: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24
  },
  expiredTitle: {
    ...FONTS.textMedium,
    textTransform: 'uppercase'
  },
  expiredDescription: {
    ...FONTS.textRegular,
    color: COLORS.error
  },
  blurry: {
    width: 200,
    height: 200
  },
  screenControls: {
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    right: 144,
    top: 50
  },
  paymentCompleted: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  paymentCompletedHR: {
    borderColor: '#E7ECF3',
    borderBottomWidth: 1,
    marginTop: 16,
    marginBottom: 16
  },
  paymentCompletedText: {
    ...FONTS.textMedium,
    fontWeight: 'normal',
    marginLeft: 16
  },
  statusesBox: {},
  statusesList: {
    height: 405
  },
  branding: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: '100%',
    marginTop: 24
  },
  poweredBy: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: '100%',
    marginTop: 24,
    marginRight: 12
  },
  poweredByCompany: {
    ...FONTS.h3,
    color: COLORS.primaryViolet,
    fontSize: 20,
    fontWeight: '700',
    marginLeft: 4
  },
  poweredByLabel: {
    ...FONTS.disc,
    color: COLORS.primaryViolet,
    marginRight: 4
  },
  reducedLogo: {
    transform: [
      {
        scale: 0.75
      }
    ]
  },
  totalTextFiat: {
    ...FONTS.h3,
    fontWeight: '400',
    fontStyle: 'normal',
    textAlign: 'right',
    fontFamily: 'DINPro'
  },
  imagePreview: {
    width: 82,
    height: 82,
    borderRadius: 4
  },
  itemDescription: {
    ...FONTS.textMedium,
    color: COLORS.primaryViolet,
    textTransform: 'uppercase',
    marginBottom: 16
  },
  collapseText: {
    ...FONTS.disc,
    color: COLORS.primaryViolet
  },
  description: {
    ...FONTS.h4
  },
  bold: {
    fontWeight: 'bold'
  }
})
