import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const ExclamationErrorWarning: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const Rect = useComponent('rect')

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
      <Rect width={40} height={40} fill="#FBC355" fillOpacity={0.1} rx={10.667} />
      <Rect width={20} height={20} x={10} y={10} fill="#FBC355" rx={6.222} />
      <Path fill="#fff" d="M19 21.75V15h1.5v6.75H19Zm0 3.75V24h1.5v1.5H19Z" />
    </Svg>
  )
}

export default ExclamationErrorWarning
