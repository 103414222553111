import { gql } from '@apollo/client'

import { SALE_FRAGMENT } from '../fragments/SaleFragment'

export const CREATE_IN_PERSON_SALE = gql`
  mutation CreateInPersonSale($data: CreateSaleInput!) {
    createSale(data: $data) {
      sale {
        ...SaleFragment
      }
      error {
        errorMsg
      }
    }
  }

  ${SALE_FRAGMENT}
`
