import * as yup from 'yup'
import YupPassword from 'yup-password'

import i18nDef from '../../locales'

YupPassword(yup)

export const passwordYup = yup
  .string()
  .min(8, i18nDef.t('common:forms.passwordTooShort') as string)
  .minLowercase(1, i18nDef.t('common:forms.passwordMustContain1Lower') as string)
  .minUppercase(1, i18nDef.t('common:forms.passwordMustContain1Upper') as string)
  .minNumbers(1, i18nDef.t('common:forms.passwordMustContainNumber') as string)
  .minSymbols(1, i18nDef.t('common:forms.passwordMustContainSpecialChar') as string)
  .required(i18nDef.t('common:forms.passwordRequired') as string)

export const passwordConfirmYup = yup
  .string()
  .min(8, i18nDef.t('common:forms.passwordTooShort') as string)
  .oneOf([yup.ref('password'), null], i18nDef.t('common:forms.passwordNotMatch') as string)
  .required('Repeat password is a required field')
