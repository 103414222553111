import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const CheckSuccess: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const Rect = useComponent('rect')

  return (
    <Svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect width={40} height={40} rx={10.667} fill="#E9F7EF" />
      <Rect x={9.333} y={9.333} width={21.333} height={21.333} rx={6.222} fill="#50A371" />
      <Path
        d="M25.089 16.65a.765.765 0 0 0-.247-.152.818.818 0 0 0-.83.152l-5.655 5.19-2.376-2.184a.79.79 0 0 0-.255-.15.84.84 0 0 0-.593.009.78.78 0 0 0-.249.158.709.709 0 0 0-.164.233.66.66 0 0 0 .01.544c.041.086.1.163.173.228l2.915 2.672c.07.065.154.117.247.152a.82.82 0 0 0 .83-.152l6.194-5.677a.698.698 0 0 0 .18-.232.646.646 0 0 0 0-.558.7.7 0 0 0-.18-.233Z"
        fill="#fff"
      />
    </Svg>
  )
}

export default CheckSuccess
