import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const Question: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')

  return (
    <Svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M9 .375A8.125 8.125 0 1 0 17.125 8.5 8.133 8.133 0 0 0 9 .375ZM9 13.5a.938.938 0 1 1 0-1.875.938.938 0 0 1 0 1.875Zm.625-3.82v.07a.625.625 0 1 1-1.25 0v-.625A.625.625 0 0 1 9 8.5a1.562 1.562 0 1 0-1.563-1.563.625.625 0 1 1-1.25 0A2.813 2.813 0 1 1 9.626 9.68Z"
        fill="#606690"
      />
    </Svg>
  )
}

export default Question
