import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const CopyText: FC<{ fill?: string }> = props => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')

  return (
    <Svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12.719 19H6.75a2.503 2.503 0 0 1-2.5-2.5V8.031c0-1.378 1.121-2.5 2.5-2.5h5.969c1.378 0 2.5 1.122 2.5 2.5V16.5c0 1.378-1.122 2.5-2.5 2.5ZM6.75 6.781c-.69 0-1.25.561-1.25 1.25V16.5c0 .69.56 1.25 1.25 1.25h5.969c.689 0 1.25-.56 1.25-1.25V8.031c0-.689-.561-1.25-1.25-1.25H6.75Zm10.969 8.157V5.5c0-1.379-1.122-2.5-2.5-2.5H8.28a.625.625 0 1 0 0 1.25h6.938c.689 0 1.25.56 1.25 1.25v9.438a.625.625 0 1 0 1.25 0Z"
        fill={props?.fill || '#FCFCFD'}
      />
    </Svg>
  )
}

export default CopyText
