import { useRef, useState } from 'react'
import { Animated } from 'react-native'
import { COLORS } from '../../../styles'

export const useAnimatedLabel = () => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const animatedIsFocused = useRef(new Animated.Value(0)).current

  const animatedLabelStyle = {
    top: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [16, 8]
    }),
    fontSize: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [14, 12]
    }),
    color: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [COLORS.primaryViolet, COLORS.primaryViolet]
    })
  } as any

  return {
    isFocused,
    setIsFocused,
    animatedIsFocused,
    animatedLabelStyle
  }
}
