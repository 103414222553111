import { gql } from '@apollo/client'

export const SALE_FRAGMENT = gql`
  fragment SaleFragment on Sale {
    uid
    userId
    status
    errorType
    expiredType
    type
    fiatCurrencyId
    fiatCurrencyVolume
    cryptoCurrencyId
    cryptoCurrencyVolume
    customerName
    customerEmail
    customerPhone
    description
    invoiceExpirationDate
    isKycRequired
    kycId
    kycReportFile
    proxyWalletAddress
    paymentProcessStartDate
    paymentProcessExpirationDate
    paymentProcessEndDate
    isHedgingEnabled
    hedgingStatus
    isPaymentFound
    paidAmount
    amountToPay
    isFullyPaid
    isPaymentReceived
    isAmlCompleted
    isKrakenDeposited
    isCryptoSold
    isSentToP3
    isReceivedOnP3
    rate
    createdAt
    urlsToFiles
  }
`
export const SALE_FRAGMENT_WITH_FEES = gql`
  fragment SaleFragmentWithFees on Sale {
    uid
    userId
    status
    errorType
    expiredType
    type
    fiatCurrencyId
    fiatCurrencyVolume
    cryptoCurrencyId
    cryptoCurrencyVolume
    customerName
    customerEmail
    customerPhone
    description
    invoiceExpirationDate
    isKycRequired
    kycId
    kycReportFile
    proxyWalletAddress
    paymentProcessStartDate
    paymentProcessExpirationDate
    paymentProcessEndDate
    isHedgingEnabled
    hedgingStatus
    isPaymentFound
    paidAmount
    amountToPay
    isFullyPaid
    isPaymentReceived
    isAmlCompleted
    isKrakenDeposited
    isCryptoSold
    isSentToP3
    isReceivedOnP3
    rate
    createdAt
    urlsToFiles
    fee
    paymentBlockchainTransactionsFee
    paymentBlockchainTransactionsFiatFee
    paymentBlockchainTransactionsFeeCurrency
    krakenDepositsFee
    krakenDepositsFiatFee
    krakenDepositsFeeCurrency
    hedgeFee
    hedgeAndKrakenTradesFeeCurrency
    krakenWithdrawalsFee
    krakenWithdrawalsFeeCurrency
    p3InternalFee
    p3InternalFeeCurrency
    krakenTradesFee
  }
`
