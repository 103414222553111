import moment from 'moment'

export const toNumber = (s: string) => (s ? +s.replace(/,/g, '') : 0)

export const strip = (s: string, precision: number) => {
  let n = parseFloat(s).toPrecision(Math.min(precision || 1, 100))

  // Use a regular expression to remove trailing zeros after a decimal point
  n = n.replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1')

  return n
}

export const getFormattedDate = (date: Date) => {
  return moment.utc(date).local().format('DD MMM YYYY')
}

export const currency = (value: string | number = 0, currency = 'GBP', sign = true, decimals = 0) => {
  if (parseFloat(String(value)) > 0 && parseFloat(String(value)) < 1) {
    return value
  }

  const result = Intl.NumberFormat('en-US', {
    currency: currency,
    style: 'currency',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(parseFloat(String(value)))

  return sign ? result : result.replace('£', '').replace('$', '').replace('€', '')
}

export const trimUid = (uid?: string | null) => {
  if (!uid) {
    return ' – '
  }

  return uid.split('-')[0]
}

export const upperFirst = (s: string): string => s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()
