import React from 'react'
import ReactDOM from 'react-dom'
import { AppWeb } from '@cryptoswift/shared/src/AppWeb'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <AppWeb />
  </React.StrictMode>,
  document.getElementById('root')
)
