import { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
  CheckHedgeAvailability,
  CheckHedgeAvailabilityVariables
} from '../../gql/queries/__generated__/CheckHedgeAvailability'
import { CHECK_HEDGE_AVAILABILITY } from '../../gql/queries/checkHedgeAvailability'
import { useCurrency } from '../api/useCurrency'
import { useExchange } from '../api/useExchange'

export const useHedgeAvailability = (fiatCurrencyVolume: string, fiatCurrencyId: number, cryptoCurrencyId: number) => {
  const [isHedgingAvailable, setIsHedgingAvailable] = useState(false)
  const [isWarningVisible, setIsWarningVisible] = useState(true)

  const { findOne } = useCurrency()
  const { result, loading: isExchanging } = useExchange({
    amount: fiatCurrencyVolume,
    cryptoId: cryptoCurrencyId,
    fiatId: fiatCurrencyId
  })

  const currencySymbol = findOne(cryptoCurrencyId)?.symbol ?? ''

  const { data, loading: isCheckingHedging } = useQuery<CheckHedgeAvailability, CheckHedgeAvailabilityVariables>(
    CHECK_HEDGE_AVAILABILITY,
    {
      skip: !cryptoCurrencyId || !result || !currencySymbol,
      fetchPolicy: 'network-only',
      variables: {
        cryptoCurrencyId: Number(cryptoCurrencyId),
        volume: +result?.amount
      },
      onCompleted: result => {
        const isAvailable = Boolean(result.checkHedgeAvailability?.isAvailable)

        setIsHedgingAvailable(isAvailable)
        setIsWarningVisible(isAvailable)
      },
      onError: () => {
        setIsHedgingAvailable(false)
        setIsWarningVisible(false)
      }
    }
  )

  const onShowWarning = useCallback(() => {
    setIsWarningVisible(true)
  }, [])

  return {
    isExchanging,
    isHedgingAvailable,
    isCheckingHedging,
    isWarningVisible,
    onShowWarning,
    data
  }
}
