import { gql } from '@apollo/client'

export const RESTART_SALE = gql`
  mutation RestartSale($uid: UUID!) {
    restartSale(uid: $uid) {
      sale {
        uid
        status
      }
    }
  }
`
