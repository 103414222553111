import { gql } from '@apollo/client'

import { SALE_FRAGMENT } from '../fragments/SaleFragment'

export const START_IN_PERSON_SALE = gql`
  mutation StartInPersonSale($uid: UUID!) {
    startSale(uid: $uid) {
      error {
        errorMsg
      }
      sale {
        ...SaleFragment
      }
    }
  }

  ${SALE_FRAGMENT}
`
