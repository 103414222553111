import { gql } from '@apollo/client'

export const GET_KYC = gql`
  query GetKyc($kycId: UUID!) {
    getKyc(kycId: $kycId) {
      id
      reportId
      lastReport {
        globalStatus
        checks {
          message
          status
          errorCause {
            message
          }
          subChecks {
            message
            status
            errorCause {
              message
            }
          }
        }
      }
    }
  }
`
