import React, { FC } from 'react'

import { useCountdown } from '../../../hooks/utils/useCountdown'

const Countdown: FC<{
  children: (v: { timeLeft: string; total: number }) => JSX.Element
  expiresAt?: string | null
  refetch: () => void
}> = ({ children, expiresAt, refetch }) => {
  const { timeLeft, total } = useCountdown(expiresAt, refetch)

  return children({ timeLeft, total })
}

export default Countdown
