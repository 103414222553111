import React, { FC, useEffect } from 'react'
import { ActivityIndicator } from 'react-native'

import { CreateSaleInput } from '../../__generated__/globalTypes'
import { useHedgeAvailability } from '../../hooks/shared/useHedgeAvailability'

import HedgeErrorMessage from './HedgeErrorMessage'

const HedgeCheck: FC<{ data: Partial<CreateSaleInput>; onComplete: () => void }> = ({ data, onComplete }) => {
  const {
    isExchanging,
    isCheckingHedging,
    isHedgingAvailable,
    data: hedgeData
  } = useHedgeAvailability(data.fiatCurrencyVolume!, data.fiatCurrencyId!, (data as CreateSaleInput).cryptoCurrencyId)

  useEffect(() => {
    function checkAvailability() {
      if (!hedgeData) return

      if (isExchanging || isCheckingHedging) return

      if (isHedgingAvailable) {
        onComplete()
      }
    }

    checkAvailability()
  }, [isExchanging, isCheckingHedging, isHedgingAvailable, hedgeData])

  if (isExchanging || isCheckingHedging) {
    return <ActivityIndicator size="large" />
  }

  return !isHedgingAvailable ? <HedgeErrorMessage onComplete={onComplete} /> : <ActivityIndicator size="large" />
}

export default HedgeCheck
