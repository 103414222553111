import React, { FC, memo } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import Logo from '../components/icons/Logo'

const NoMatch: FC = () => {
  return (
    <View style={styles.container}>
      <Logo />
      <Text style={styles.title}>404</Text>
      <Text style={styles.subtitle}>Page Not Found</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 32
  },
  logo: {
    width: 320, // or any size you prefer
    height: 320, // or any size you prefer
    marginBottom: 20
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333333' // or any color you prefer
  },
  subtitle: {
    fontSize: 18,
    color: '#666666', // or any color you prefer
    marginTop: 10
  }
})

export default memo(NoMatch)
