import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useMutation } from '@apollo/client'

import Button from '../../components/controls/Button'
import Input from '../../components/controls/Input'
import LogoBlue from '../../components/icons/LogoBlue'
import CheckSuccess from '../../components/icons/Utility/CheckSuccess'
import ExclamationError from '../../components/icons/Utility/ExclamationError'
import Paper from '../../components/Paper'
import { ResetPassword, ResetPasswordVariables } from '../../gql/mutations/__generated__/ResetPassword'
import { RESET_PASSWORD } from '../../gql/mutations/resetPassword'
import { FONTS } from '../../styles'

import { passwordConfirmYup, passwordYup } from './validations'

const schema = yup
  .object({
    password: passwordYup,
    passwordConfirm: passwordConfirmYup
  })
  .required()

const ResetPasswordScreen: FC = () => {
  const [params] = useSearchParams()
  const [done, setDone] = useState<boolean | null>(null)
  const [error, setError] = useState('')

  const [reset] = useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD)

  const { control, handleSubmit } = useForm<ResetPasswordVariables>({
    defaultValues: {
      password: '',
      passwordConfirm: ''
    },
    mode: 'all',
    reValidateMode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema)
  })

  const submitHandler = () => {
    handleSubmit(values => {
      const [userId, uuid] = [params.get('userId'), params.get('uuid')]

      if (userId && uuid) {
        reset({ variables: { ...values, userId, confirmationId: uuid } })
          .then(() => {
            setDone(true)
            setError('')
          })
          .catch(e => {
            setDone(false)
            setError(e?.graphQLErrors?.[0]?.message)
          })
      }
    })()
  }

  const { t } = useTranslation()

  return (
    <View>
      <View
        aria-label="full-page"
        style={{
          marginTop: 26,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 32,
          width: 564,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <LogoBlue />
        <Text style={{ marginLeft: 12, ...FONTS.h3 }}>{t('common:appTitle')}</Text>
      </View>
      <Paper
        aria-label="full-page-paper"
        style={{
          paddingBottom: 32,
          paddingLeft: 32,
          paddingTop: 32,
          paddingRight: 32,
          minWidth: 564
        }}
      >
        {!done && !error && (
          <View>
            <Text style={FONTS.h2}>{t('common:resetPassword.resetPassword')}</Text>
            <Text style={{ ...FONTS.h4, marginTop: 16 }}>{t('common:resetPassword.resetPasswordBelow')}</Text>
            <View style={{ marginTop: 16 }}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    fullWidth
                    secureTextEntry
                    error={error?.message}
                    keyboardType={'default'}
                    label={t('common:forms.createNewPassword') as string}
                    required
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="password"
              />
            </View>
            <View style={{ marginTop: 16 }}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    fullWidth
                    secureTextEntry
                    error={error?.message}
                    keyboardType={'default'}
                    label={t('common:forms.repeatNewPassword') as string}
                    required
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="passwordConfirm"
              />
            </View>
            <View style={{ marginTop: 24 }}>
              <Button onClick={submitHandler}>{t('common:resetPassword.confirm')}</Button>
            </View>
          </View>
        )}
        {done && (
          <>
            <View>
              <View style={styles.row}>
                <View style={{ width: 24 }}>
                  <CheckSuccess />
                </View>
                <Text style={styles.text}>{t('common:resetPassword.passwordChanged')}</Text>
              </View>
              <View style={{ marginTop: 4 }}>
                <Button
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.open('cryptoswift://home')
                  }}
                >
                  {t('common:resetPassword.goToApp')}
                </Button>
              </View>
            </View>
          </>
        )}
        {!done && Boolean(error) && (
          <>
            <View>
              <View style={[styles.row, { marginBottom: 0 }]}>
                <View style={{ width: 24 }}>
                  <ExclamationError />
                </View>
                <Text style={styles.text}>{error}</Text>
              </View>
            </View>
          </>
        )}
      </Paper>
    </View>
  )
}

export default ResetPasswordScreen

const styles = StyleSheet.create({
  text: {
    ...FONTS.h2,
    marginLeft: 24
  },
  row: {
    flexDirection: 'row',
    marginBottom: 24
  }
})
