import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Animated,
  Keyboard,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TouchableHighlight,
  View
} from 'react-native'
import MaskInput, { createNumberMask } from 'react-native-mask-input'

import { BACKGROUNDS, COLORS } from '../../styles'
import { currency as currencyFormat } from '../../utils/formatters'
import EyeClosed from '../icons/Utility/EyeClosed'
import EyeOpen from '../icons/Utility/EyeOpen'

import { useAnimatedLabel } from './hooks/useAnimatedLabel'
import * as controlsCss from './styles/control'

const CURRENCY = createNumberMask({
  separator: '.',
  delimiter: ',',
  precision: 0
})

const PHONE = ['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const getCurrencySymbol = (currencyName: string) => {
  return String(currencyFormat(0, currencyName)).slice(0, 1)
}

const Input: FC<
  {
    phone?: boolean
    fullWidth?: boolean
    hint?: string
    currency?: string
    error?: string
    label?: string
    required?: boolean
  } & TextInputProps
> = ({
  hint,
  currency,
  label,
  error,
  required = false,
  fullWidth = false,
  phone = false,
  secureTextEntry = false,
  ...restProps
}) => {
  const inputRef = useRef<TextInput | null>(null)
  const { isFocused, setIsFocused, animatedIsFocused, animatedLabelStyle } = useAnimatedLabel()
  const [showPassword, setShowPassword] = useState(secureTextEntry)

  useEffect(() => {
    Animated.timing(animatedIsFocused, {
      toValue: isFocused || restProps.value !== '' ? 1 : 0,
      duration: 200,
      useNativeDriver: false
    } as any).start()
  }, [isFocused, restProps.value])

  const autoSuggest = {
    blurOnSubmit: false,
    onSubmitEditing: () => Keyboard.dismiss()
  }

  let control = (
    <TextInput
      secureTextEntry={showPassword}
      autoCapitalize={restProps?.keyboardType === 'email-address' ? 'none' : undefined}
      ref={inputRef}
      style={[
        styles.input,
        isFocused && styles.focusedTextInput,
        fullWidth && controlsCss.CONTROL_FULL_WIDTH,
        Boolean(error) && { borderBottomColor: COLORS.error }
      ]}
      {...restProps}
      onFocus={e => {
        restProps?.onFocus?.(e)
        setIsFocused(true)
      }}
      onBlur={e => {
        restProps?.onBlur?.(e)
        setIsFocused(false)
      }}
      {...(showPassword ? autoSuggest : undefined)}
    />
  )

  if (Boolean(currency) || phone) {
    control = (
      <MaskInput
        keyboardType={'numeric'}
        ref={inputRef}
        style={[
          styles.input,
          isFocused && styles.focusedTextInput,
          Boolean(currency) && !phone && { paddingLeft: 14 },
          fullWidth && controlsCss.CONTROL_FULL_WIDTH,
          Boolean(error) && { borderBottomColor: COLORS.error }
        ]}
        {...restProps}
        placeholder={''}
        mask={Boolean(currency) ? CURRENCY : PHONE}
        onFocus={(e: NativeSyntheticEvent<TextInputFocusEventData>) => {
          restProps?.onFocus?.(e)
          setIsFocused(true)
        }}
        onBlur={(e: NativeSyntheticEvent<TextInputFocusEventData>) => {
          restProps?.onBlur?.(e)
          setIsFocused(false)
        }}
      />
    )
  }

  return (
    <TouchableHighlight
      activeOpacity={1}
      underlayColor={BACKGROUNDS.bg2}
      onPress={() => {
        inputRef.current?.focus()
      }}
    >
      <View>
        <View style={[styles.formControl, fullWidth && controlsCss.CONTROL_FULL_WIDTH]}>
          {label && (
            <Animated.Text style={[styles.label, animatedLabelStyle]}>
              {label}
              {required && <Text style={{ color: COLORS.error, marginLeft: 2, fontSize: 14 }}>*</Text>}
            </Animated.Text>
          )}
          <View>
            {Boolean(currency) && (isFocused || Boolean(restProps.value)) && (
              <Text style={styles.currencySign}>{Boolean(currency) ? getCurrencySymbol(currency!) : ''}</Text>
            )}
            {control}
            {secureTextEntry && (
              <View style={styles.eye}>
                <Pressable onPress={() => setShowPassword(prev => !prev)}>
                  {!showPassword ? <EyeOpen /> : <EyeClosed />}
                </Pressable>
              </View>
            )}
          </View>
        </View>
        {Boolean(error) && (
          <View>
            <Text style={styles.error}>{error}</Text>
          </View>
        )}
        {Boolean(hint) && (
          <View>
            <Text style={styles.hint}>{hint}</Text>
          </View>
        )}
      </View>
    </TouchableHighlight>
  )
}

export default Input

const styles = StyleSheet.create({
  formControl: controlsCss.CONTROL_CONTAINER,
  label: controlsCss.CONTROL_LABEL,
  input: controlsCss.CONTROL_INPUT,
  focusedTextInput: controlsCss.FOCUSED_BORDER_COLOR,
  error: controlsCss.CONTROL_ERROR,
  hint: controlsCss.CONTROL_HINT,
  currencySign: {
    position: 'absolute',
    top: 28,
    left: 2,
    color: COLORS.primaryViolet,
    ...(Platform.OS === 'android' && {
      top: 30,
      left: 2
    })
  },
  eye: {
    position: 'absolute',
    right: 8,
    top: 16
  }
})
