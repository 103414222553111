import bip21 from 'bip21'
import { BlockchainEnum } from '../__generated__/globalTypes'

export const LINKS_GENERATOR = {
  [BlockchainEnum.BITCOIN]: (address: string, amount: number, label: string) =>
    bip21.encode(address, { amount, label }),
  [BlockchainEnum.ETHEREUM]: (address: string, amount: number, label: string) =>
    bip21.encode(address, { amount, label }, 'ethereum'),
  [BlockchainEnum.TRON]: (address: string, amount: number, label: string) =>
    bip21.encode(address, { amount, label }, 'tron')
}
