import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const EyeOpen: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')

  return (
    <Svg width={22} height={19} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M11 .5c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.82 9-5.391 0-9.877-3.88-10.818-9C1.12 4.38 5.608.5 11 .5Zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0 9.005 9.005 0 0 0 8.777 7Zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        fill="#606690"
      />
    </Svg>
  )
}

export default EyeOpen
