import { StyleSheet } from 'react-native'

import { ADDITIONAL_COLORS, BACKGROUNDS, COLORS, FONTS } from '../../../styles'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  item: {
    width: '30%'
  },
  label: {
    ...FONTS.textRegular,
    color: COLORS.primaryViolet
  },
  text: {
    ...FONTS.h4,
    color: COLORS.primaryBlack,
    marginTop: 4
  },
  headline: {
    ...FONTS.h2
  },
  headlineRow: {
    paddingBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  itemDescription: {
    ...FONTS.textMedium,
    color: COLORS.primaryViolet,
    textTransform: 'uppercase',
    marginBottom: 16
  },
  hr: {
    borderColor: '#E7ECF3',
    borderBottomWidth: 1,
    marginTop: 16,
    marginBottom: 16
  },
  total: {
    marginTop: 16,
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: BACKGROUNDS.bg1
  },
  asterisk: {
    color: COLORS.primaryBlack,
    fontSize: 16,
    paddingRight: 8
  },
  exchangeExample: {
    ...FONTS.textMedium,
    color: COLORS.primaryViolet,
    textAlign: 'right',
    marginTop: 4,
    marginBottom: 12
  },
  cta: {
    marginTop: 16
  },
  errorMessage: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  errorMessageHeadline: {
    ...FONTS.textBold,
    color: ADDITIONAL_COLORS.red,
    textTransform: 'uppercase'
  },
  errorMessageText: {
    ...FONTS.textRegular,
    color: ADDITIONAL_COLORS.red
  },
  branding: {
    ...FONTS.h3,
    marginLeft: 16
  },
  brandingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    paddingRight: 24
  },
  logo: {
    width: 40,
    height: 40,
    borderRadius: 50
  },
  imagePreview: {
    width: 82,
    height: 82,
    marginTop: 16,
    borderRadius: 4
  },
  collapseText: {
    ...FONTS.disc,
    color: COLORS.primaryViolet
  },
  videoWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    top: 0,
    overflow: 'hidden'
  },
  errorDescription: {
    ...FONTS.h4
  }
})

export default styles
