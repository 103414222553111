import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

import Button from '../../components/controls/Button'
import ExclamationErrorWarning from '../../components/icons/Utility/ExclamationErrorWarning'
import Paper from '../../components/Paper'
import { FONTS } from '../../styles'

const HedgeErrorMessage: FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const { t } = useTranslation()

  return (
    <Paper
      style={{
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        minWidth: 510,
        width: 510
      }}
      animate
      aria-label="notofication-modal"
    >
      <View>
        <View style={styles.row}>
          <View>
            <ExclamationErrorWarning />
          </View>
          <Text style={styles.text}>{t('common:hedgingAttention.attention')}</Text>
        </View>
        <Text style={styles.description}>{t('common:hedgingAttention.message')}</Text>
        {Boolean(onComplete) && (
          <View style={{ marginTop: 24 }}>
            <Button onClick={onComplete}>
              <Text>{t('common:hedgingAttention.proceed')}</Text>
            </Button>
          </View>
        )}
      </View>
    </Paper>
  )
}

export default memo(HedgeErrorMessage)

const styles = StyleSheet.create({
  text: {
    ...FONTS.h2,
    marginLeft: 24
  },
  row: {
    flexDirection: 'row',
    marginBottom: 24
  },
  description: {
    ...FONTS.h4
  }
})
