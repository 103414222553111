import { gql } from '@apollo/client'

export const CHECK_KYC = gql`
  mutation CheckKyc($kycId: UUID!, $clientName: String!) {
    checkKyc(kycId: $kycId, clientName: $clientName) {
      id
      reportId
      lastReport {
        globalStatus
        checks {
          message
          status
          errorCause {
            message
          }
          subChecks {
            message
            status
            errorCause {
              message
            }
          }
        }
      }
    }
  }
`
