import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '../fragments/UserFragment'

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($userId: UUID!, $confirmationId: UUID!) {
    confirmEmail(userId: $userId, confirmationId: $confirmationId) {
      user {
        ...UserFragment
      }
    }
  }

  ${USER_FRAGMENT}
`
