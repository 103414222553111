import React, { createContext, FC, useContext, useMemo } from 'react'

const ComponentsContext = createContext({
  components: new Map()
})

export const useComponent = (key: string) => useContext(ComponentsContext).components.get(key)

export const useComponents = () => useContext(ComponentsContext)

export const ComponentsProvider: FC<{
  components: Map<string, JSX.Element>
  children: React.ReactNode
}> = ({ children, components }) => {
  const value = useMemo(() => ({ components }), [components])

  return <ComponentsContext.Provider value={value}>{children}</ComponentsContext.Provider>
}
