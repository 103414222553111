import { useEffect, useState } from 'react'

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0')
}

const getDiff = (date: string) => {
  const dateNow = new Date()

  const nowUtc = Date.UTC(
    dateNow.getUTCFullYear(),
    dateNow.getUTCMonth(),
    dateNow.getUTCDate(),
    dateNow.getUTCHours(),
    dateNow.getUTCMinutes(),
    dateNow.getUTCSeconds()
  )

  const userTimezoneOffset = dateNow.getTimezoneOffset() * 60000
  const dateExpired = new Date(new Date(date).getTime() - userTimezoneOffset)

  return (dateExpired.valueOf() - nowUtc.valueOf()) / 1000
}
export const useCountdown = (date?: string | null, cbDone?: () => void) => {
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const [originalTime, setOriginalTime] = useState<number>(() => (date ? getDiff(date) : 0))

  useEffect(() => {
    if (originalTime) {
      setTimeLeft(originalTime)
    }
  }, [originalTime])

  useEffect(() => {
    setOriginalTime(date ? getDiff(date) : 0)
  }, [date])

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft || timeLeft <= 0) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      const newTime = timeLeft - 1

      if (newTime <= 0) {
        cbDone?.()
        setTimeLeft(0)
        clearInterval(intervalId)
      }

      setTimeLeft(newTime)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  const minutes = Math.floor(timeLeft / 60)
  const seconds = Math.trunc(timeLeft % 60)

  return {
    total: timeLeft,
    timeLeft: timeLeft > 0 ? `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}` : '',
    minutes,
    seconds
  }
}
