import { gql } from '@apollo/client'

export const GET_COMPANY_BY_USER_ID = gql`
  query GetUserCompanyByUserId($userId: UUID!) {
    getUserCompanyByUserId(userId: $userId) {
      currencyId
      id
      logoUrl
      name
    }
  }
`
