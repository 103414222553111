import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { FiatCryptoInput } from '../../__generated__/globalTypes'
import { FiatInCrypto, FiatInCryptoVariables } from '../../gql/queries/__generated__/FiatInCrypto'
import { FIAT_IN_CRYPTO } from '../../gql/queries/fiatInCrypto'

export const useExchange = ({ amount, cryptoId, fiatId }: Partial<FiatCryptoInput>) => {
  const { data, loading } = useQuery<FiatInCrypto, FiatInCryptoVariables>(FIAT_IN_CRYPTO, {
    variables: {
      data: {
        amount,
        cryptoId: Number(cryptoId!),
        fiatId: Number(fiatId!)
      } as FiatCryptoInput
    },
    skip: cryptoId === -1 || !amount || !cryptoId || !fiatId,
    fetchPolicy: 'network-only'
  })

  const result = useMemo(() => data?.indicativePrice, [data?.indicativePrice])

  return { result, loading }
}
