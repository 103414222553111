import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { Sale, SaleVariables } from '../../gql/queries/__generated__/Sale'
import { SaleWithFees } from '../../gql/queries/__generated__/SaleWithFees'
import { SALE, SALE_WITH_FEES } from '../../gql/queries/sale'

export const useSale = (uid?: string) => {
  const { data, loading, refetch, error } = useQuery<Sale, SaleVariables>(SALE, {
    variables: {
      uid: uid as string
    },
    skip: !uid,
    fetchPolicy: 'network-only'
  })

  // useSubscription<SaleSubscription, SaleSubscriptionVariables>(SALE_SUBSCRIPTION, {
  //   skip: !uid,
  //   variables: {
  //     uid: uid as string
  //   },
  //   onData: response => {
  //     console.log('response', response)
  //
  //     if (response && response.data) {
  //       // Assuming the subscription response has the updated sale data
  //       const updatedSale = response.data.data?.sale // Replace with the correct path if different
  //
  //       if (updatedSale) {
  //         const saleCachedData = client.readQuery<Sale, SaleVariables>({
  //           query: SALE,
  //           variables: {
  //             uid: uid as string
  //           }
  //         })
  //
  //         if (!saleCachedData?.sale) return
  //
  //         // Write the new data to the cache
  //         client.writeQuery({
  //           query: SALE,
  //           variables: {
  //             uid: uid as string
  //           },
  //           data: {
  //             sale: {
  //               __typename: 'SaleType', // Replace with the correct typename
  //               sale: updatedSale
  //             }
  //           }
  //         })
  //       }
  //     }
  //   }
  // })

  const result = useMemo(() => data?.sale?.sale, [data?.sale?.sale])

  return { result, loading, refetch, error }
}
export const useSaleWithFee = (uid?: string) => {
  const { data, loading, refetch, error } = useQuery<SaleWithFees, SaleVariables>(SALE_WITH_FEES, {
    variables: {
      uid: uid as string
    },
    skip: !uid,
    fetchPolicy: 'network-only'
  })

  const result = useMemo(() => data?.sale?.sale, [data?.sale?.sale])

  return { result, loading, refetch, error }
}
