import React, { FC, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import CheckSmall from '@cryptoswift/shared/src/components/icons/Utility/CheckSmall'
import { COLORS, FONTS } from '@cryptoswift/shared/src/styles'

import Question from '../../../components/icons/Question/Question'
import { useComponent } from '../../../providers/Components'

export enum ProcessStepStatus {
  Inactive,
  Pending,
  Done
}

const PaymentProcessStep: FC<{
  status?: ProcessStepStatus
  title?: string
  tooltipText?: string
  pendingText?: string
  successText?: string
  style?: ViewStyle
  isDisabled?: boolean
}> = ({
  status = ProcessStepStatus.Inactive,
  title = '',
  tooltipText = '',
  pendingText = '',
  successText = '',
  style,
  isDisabled = false
}) => {
  const Tooltip = useComponent('tooltip')
  const [toolTipVisible, setToolTipVisible] = useState(false)

  return (
    <View style={[styles.container, style]}>
      <View style={styles.headerContainer}>
        <View
          style={[
            styles.circle,
            !isDisabled && status === ProcessStepStatus.Pending && styles.circlePending,
            !isDisabled && status === ProcessStepStatus.Done && styles.circleSuccess
          ]}
        >
          {status === ProcessStepStatus.Done && <CheckSmall fill={'#ffffff'} />}
        </View>
        <Text style={styles.title}>{title}</Text>
        {Boolean(tooltipText) && (
          <Tooltip
            content={<Text>{tooltipText}</Text>}
            placement="bottom"
            isVisible={toolTipVisible}
            onClose={() => setToolTipVisible(false)}
          >
            <TouchableOpacity onPress={() => setToolTipVisible(v => !v)}>
              <Question />
            </TouchableOpacity>
          </Tooltip>
        )}
      </View>
      {!isDisabled && [ProcessStepStatus.Pending].includes(status) && Boolean(pendingText) && (
        <Text style={styles.status}>{pendingText}</Text>
      )}
      {!isDisabled && status === ProcessStepStatus.Done && Boolean(successText) && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={[styles.status]}>{successText}</Text>
        </View>
      )}
    </View>
  )
}

export default PaymentProcessStep

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 24
  },
  circle: {
    borderWidth: 1.5,
    borderColor: '#D9DBE8',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 8
  },
  circleText: {
    ...FONTS.textMedium,
    textAlign: 'center'
  },
  circleSuccess: {
    borderColor: '#2ED670',
    backgroundColor: '#2ED670'
  },
  circlePending: {
    backgroundColor: '#A3D7B8',
    borderColor: '#A3D7B8'
  },
  title: {
    ...FONTS.textMedium,
    textTransform: 'uppercase',
    marginRight: 8
  },
  status: {
    ...FONTS.textRegular,
    color: COLORS.primaryViolet,
    flexWrap: 'wrap'
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
