import * as React from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'
const Camera = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const G = useComponent('g')

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={81} height={80} fill="none">
      <G fill="#606690" fillRule="evenodd" clipRule="evenodd" opacity={0.3}>
        <Path d="M35.236 15.015a6.134 6.134 0 0 0-5.473 3.383l-.806 1.617H23c-4.113 0-7.5 3.387-7.5 7.5v25c0 4.112 3.387 7.5 7.5 7.5h35c4.112 0 7.5-3.388 7.5-7.5v-25c0-4.113-3.388-7.5-7.5-7.5h-5.957l-.806-1.617a6.134 6.134 0 0 0-5.473-3.383H35.236Zm0 5h10.528c.428 0 .809.236 1 .62l1.5 2.998a2.5 2.5 0 0 0 2.236 1.382H58c1.41 0 2.5 1.09 2.5 2.5v25c0 1.41-1.09 2.5-2.5 2.5H23c-1.41 0-2.5-1.09-2.5-2.5v-25c0-1.41 1.09-2.5 2.5-2.5h7.5a2.5 2.5 0 0 0 2.236-1.382l1.5-2.998a1.11 1.11 0 0 1 1-.62ZM8 5.015a2.5 2.5 0 0 0-2.5 2.5v10a2.5 2.5 0 1 0 5 0v-7.5H18a2.5 2.5 0 1 0 0-5H8ZM8 60.015a2.5 2.5 0 0 0-2.5 2.5v10a2.5 2.5 0 0 0 2.5 2.5h10a2.5 2.5 0 1 0 0-5h-7.5v-7.5a2.5 2.5 0 0 0-2.5-2.5ZM63 5.015a2.5 2.5 0 0 0 0 5h7.5v7.5a2.5 2.5 0 1 0 5 0v-10a2.5 2.5 0 0 0-2.5-2.5H63ZM73 60.015a2.5 2.5 0 0 0-2.5 2.5v7.5H63a2.5 2.5 0 0 0 0 5h10a2.5 2.5 0 0 0 2.5-2.5v-10a2.5 2.5 0 0 0-2.5-2.5Z" />
        <Path d="M40.5 30.015c-5.493 0-10 4.506-10 10 0 5.493 4.507 10 10 10s10-4.507 10-10c0-5.494-4.507-10-10-10Zm0 5c2.791 0 5 2.209 5 5 0 2.79-2.209 5-5 5s-5-2.21-5-5c0-2.791 2.209-5 5-5Z" />
      </G>
    </Svg>
  )
}
export default Camera
