import React, { FC, useEffect, useRef, useState } from 'react'
import { BrowserView as BV, isMobile, MobileView as MV } from 'react-device-detect'
import { View } from 'react-native'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Webcam from 'react-webcam'

import { DocumentNode, useMutation } from '@apollo/client'

import { StatusEnum } from '../../../__generated__/globalTypes'
import { SERVICE_API_URL } from '../../../api'
import { UpdateInvoice, UpdateInvoiceVariables } from '../../../gql/mutations/__generated__/UpdateInvoice'
import { UPDATE_INVOICE } from '../../../gql/mutations/updateInvoice'
import { SALE } from '../../../gql/queries/sale'
import { useSale } from '../../../hooks/api/useSale'
import { getEnv } from '../../../utils/getEnvBrowser'
import KycProcess from '../KYC/KycProcess'

import styles from './styles'

const BrowserView = BV as any
const MobileView = MV as any

const AcceptInvoiceForm: FC = () => {
  const env = getEnv()
  const [searchParams] = useSearchParams()
  const currencyValue = searchParams.get('currency')
  const { id } = useParams<{ id: string }>()
  const webcamRef = useRef<any>(null)
  const { result: data, refetch: refetchSale, error: saleError } = useSale(id)
  const navigate = useNavigate()
  const [isCameraAllowed, setIsCameraAllowed] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const pickerCb = useRef((f: Record<string, any>) => {})
  const capture = React.useCallback(
    (cb: any) => {
      const imageSrc = webcamRef.current.getScreenshot()

      pickerCb.current({
        data: imageSrc.split('base64,')[1] ?? ''
      })
    },
    [webcamRef]
  )

  const [updateInvoice] = useMutation<UpdateInvoice, UpdateInvoiceVariables>(UPDATE_INVOICE as DocumentNode, {
    refetchQueries: [
      {
        query: SALE as DocumentNode,
        variables: {
          uid: id!
        }
      }
    ]
  })

  useEffect(() => {
    if (!isMobile) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => setIsCameraAllowed(true))
        .catch(() => setIsCameraAllowed(false))
    }
  }, [])

  if (
    data?.status === StatusEnum.ERROR ||
    data?.status === StatusEnum.EXPIRED ||
    Boolean(data?.paymentProcessStartDate)
  ) {
    return <Navigate to={`/invoice/${id}`} replace />
  }

  return (
    <>
      <KycProcess
        containerClickMakePicture={isMobile}
        isAllowed={isMobile || isCameraAllowed}
        url={SERVICE_API_URL.replace('{env}', env)}
        credentials={String(id)}
        clientName={data?.customerName ?? ''}
        onCompleted={r => {
          if (!r) return

          updateInvoice({
            variables: {
              data: {
                cryptoCurrencyId: Number(currencyValue),
                kycId: r.checkKyc.id
              },
              uid: id!
            }
          }).then(() => {
            navigate(`/invoice/${id}/hedge`)
          })
        }}
        pickFile={cb => {
          if (isMobile) {
            webcamRef?.current?.click()

            pickerCb.current = cb

            return
          }

          if (webcamRef.current) {
            pickerCb.current = cb

            capture(cb)
          }
        }}
        clearable={!isMobile}
      >
        <BrowserView>
          <View style={styles.videoWrapper}>
            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={430} />
          </View>
        </BrowserView>
        <MobileView>
          <input
            style={{ display: 'none' }}
            ref={webcamRef}
            id="cameraFileInput"
            type="file"
            accept="image/*"
            capture="environment"
            multiple={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement & { files: Blob[] }>) => {
              if (e.target.files && e.target.files.length > 0) {
                const [file] = e.target.files

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function () {
                  pickerCb.current({
                    ...file,
                    data: String(reader?.result).split('base64,')[1] ?? ''
                  })
                }
                reader.onerror = function (error) {
                  console.log('Error: ', error)
                }
              }
            }}
          />
        </MobileView>
      </KycProcess>
    </>
  )
}

export default AcceptInvoiceForm
