import React, { FC } from 'react'
import { Modal, ModalProps, StyleSheet, TouchableOpacity, View } from 'react-native'

const BackdropModal: FC<ModalProps> = ({ children, ...restProps }) => {
  return (
    <Modal animationType="fade" transparent={true} {...restProps}>
      <TouchableOpacity style={styles.modalContainer} onPress={restProps.onRequestClose}>
        <TouchableOpacity activeOpacity={1}>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>{children}</View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  )
}

export default BackdropModal

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)'
  },
  modal: {
    width: 155,
    height: 300
  }
})
