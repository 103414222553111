import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Pressable, Text, TouchableOpacity, View } from 'react-native'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { DocumentNode, useMutation } from '@apollo/client'

import { ExpiredTypeEnum, StatusEnum } from '../../../__generated__/globalTypes'
import Button from '../../../components/controls/Button'
import ErrorCircle from '../../../components/icons/Utility/ErrorCircle'
import ExclamationError from '../../../components/icons/Utility/ExclamationError'
import Paper from '../../../components/Paper'
import { UpdateInvoice, UpdateInvoiceVariables } from '../../../gql/mutations/__generated__/UpdateInvoice'
import { UPDATE_INVOICE } from '../../../gql/mutations/updateInvoice'
import { SALE } from '../../../gql/queries/sale'
import { useCurrency } from '../../../hooks/api/useCurrency'
import { useExchange } from '../../../hooks/api/useExchange'
import { useSale } from '../../../hooks/api/useSale'
import { analyzeHTMLContent, useReadMore } from '../../../hooks/utils/useReadMore'
import { useComponent } from '../../../providers/Components'
import { ADDITIONAL_COLORS, FONTS } from '../../../styles'
import { currency, getFormattedDate, trimUid } from '../../../utils/formatters'

import styles from './styles'

const AcceptInvoiceForm: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [previewURL, setPreviewURL] = useState<Record<string, any> | null>()
  const { findOne } = useCurrency()
  const { asSelect } = useCurrency({ cryptoOnly: true })
  const [value, setValue] = useState<string | undefined>()
  const crypto = findOne(+(value ?? -1))
  const { result: data, error: saleError } = useSale(id)
  const { result } = useExchange({
    amount: data?.fiatCurrencyVolume ?? '0',
    cryptoId: +(value ?? -1),
    fiatId: data?.fiatCurrencyId
  })
  const { onViewLayout, onTextLayout, allowReadMore, isOpen, setIsOpen, textStyle, Container } = useReadMore()

  const ImagePreview = useComponent('imagePreview')
  const Select = useComponent('select')

  const [updateInvoice] = useMutation<UpdateInvoice, UpdateInvoiceVariables>(UPDATE_INVOICE as DocumentNode, {
    refetchQueries: [
      {
        query: SALE as DocumentNode,
        variables: {
          uid: id!
        }
      }
    ]
  })

  if (saleError) {
    return (
      <Paper
        style={{
          paddingBottom: 32,
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 32,
          minWidth: 510,
          width: 510
        }}
      >
        <View>
          <View style={[styles.row, { marginBottom: 20 }]}>
            <ExclamationError />
            <Text style={[styles.text, { marginLeft: 4 }]}>{t('common:invoiceAccept.getSaleError')}</Text>
          </View>
          <Text style={styles.errorDescription}>{saleError?.message}</Text>
        </View>
      </Paper>
    )
  }

  if (!Boolean(data)) return <></>

  if (data?.status === StatusEnum.PENDING && data?.paymentProcessStartDate) {
    return <Navigate to="process" replace />
  }

  if (data?.status === StatusEnum.COMPLETED) {
    return <Navigate to="process" replace />
  }

  return (
    <Paper
      style={{
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        minWidth: 890,
        maxWidth: 890
      }}
      aria-label="invoice-accept-paper"
    >
      <View>
        <View style={styles.headlineRow} aria-label="transaction-summary-title">
          <Text style={[styles.headline]}>{t('common:invoiceAccept.saleSummary')}</Text>

          {(data?.status === StatusEnum.EXPIRED ||
            data?.status === StatusEnum.ERROR ||
            data?.status === StatusEnum.CANCELED) && (
            <View style={styles.errorMessage}>
              <View style={{ marginRight: 8 }}>
                <ErrorCircle />
              </View>
              {data?.status === StatusEnum.CANCELED && (
                <View>
                  <Text style={styles.errorMessageHeadline}>{t('common:checkout.cancelled')}</Text>
                </View>
              )}
              {Boolean(data?.errorType) && (
                <View>
                  <Text style={styles.errorMessageHeadline}>{t('common:invoiceAccept.getSaleError')}</Text>
                </View>
              )}
              {data?.expiredType === ExpiredTypeEnum.PAYMENT_EXPIRED && (
                <View>
                  <Text style={styles.errorMessageHeadline}>{t('common:invoiceAccept.qrExpired')}</Text>
                </View>
              )}
              {data?.expiredType === ExpiredTypeEnum.INVOICE_EXPIRED && (
                <View>
                  <Text style={styles.errorMessageHeadline}>{t('common:invoiceAccept.invoiceExpired')}</Text>
                  <Text style={[styles.errorMessageText]}>
                    {t('common:invoiceAccept.dueDate')}{' '}
                    {data?.invoiceExpirationDate ? getFormattedDate(new Date(data.invoiceExpirationDate)) : ' – '}
                  </Text>
                </View>
              )}
            </View>
          )}
        </View>

        <View style={styles.hr} />

        <View>
          <Text style={styles.itemDescription}>{t('common:invoiceAccept.detailedInformation')}</Text>
        </View>

        <View style={[styles.row, { marginBottom: 16 }]} aria-label="invoice-information">
          <View style={styles.item}>
            <Text style={styles.label}>{t('common:invoiceAccept.saleId')}</Text>
            <Text style={styles.text}>{trimUid(data?.uid)}</Text>
          </View>

          <View style={styles.item}>
            <Text style={[styles.label]}>{t('common:invoiceAccept.dueDate')}</Text>
            <Text
              style={[
                styles.text,
                data?.status === StatusEnum.EXPIRED &&
                  data?.expiredType === ExpiredTypeEnum.INVOICE_EXPIRED && { color: ADDITIONAL_COLORS.red }
              ]}
            >
              {data?.invoiceExpirationDate ? getFormattedDate(new Date(data.invoiceExpirationDate)) : ' – '}
            </Text>
          </View>

          <View style={styles.item}>
            <Text style={styles.label}>
              {t('common:invoiceAccept.priceIn')}* {findOne(data?.fiatCurrencyId)?.name ?? ''}
            </Text>
            <Text style={styles.text}>
              {data?.fiatCurrencyVolume
                ? currency(data.fiatCurrencyVolume, findOne(data?.fiatCurrencyId)?.symbol)
                : ' – '}
            </Text>
          </View>
        </View>

        {Boolean(data?.urlsToFiles) && (
          <View style={{ flexDirection: 'row' }}>
            {data?.urlsToFiles?.map((fileUrl, indexFile) => {
              const source = { uri: fileUrl }

              return (
                <TouchableOpacity
                  key={indexFile}
                  style={{ marginBottom: 16, marginTop: -16, marginRight: 16 }}
                  onPress={() => setPreviewURL(source)}
                >
                  <Image style={[styles.imagePreview]} source={source} />
                </TouchableOpacity>
              )
            })}
          </View>
        )}

        <ImagePreview visible={Boolean(previewURL)} source={previewURL} close={() => setPreviewURL(null)} />

        <View style={[styles.row, { marginBottom: 16 }]}>
          <View style={[styles.item, { width: '100%' }]}>
            <Text style={styles.label}>{t('common:invoiceAccept.clientName')}</Text>
            <Text style={styles.text}>{data?.customerName ?? ' – '}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={{ width: '100%' }}>
            <Text style={styles.label}>{t('common:invoiceAccept.receiptSentTo')}</Text>
            <Text style={styles.text}>{data?.customerEmail || ' – '}</Text>
          </View>
        </View>

        <View style={styles.hr} />

        <View>
          <Text style={styles.itemDescription}>{t('common:invoiceAccept.details')}</Text>
        </View>

        <View>
          <Text style={styles.label}>{t('common:invoiceAccept.description')}</Text>
          <Container
            style={{ maxHeight: 280, width: '100%' }}
            onLayout={onViewLayout}
            aria-label={isOpen && 'full-height'}
          >
            <Text
              ref={instance => {
                if (instance) {
                  const el = instance as any // HTMLElement
                  const { fontSize, fontFamily } = getComputedStyle(el)
                  const r = analyzeHTMLContent(el.innerHTML as string, fontFamily, fontSize) as any

                  onTextLayout(r)
                }
              }}
              style={[styles.text, textStyle]}
              ellipsizeMode="tail"
              numberOfLines={!isOpen ? 1 : undefined}
            >
              {`${(data?.description ?? ' – ').replace(!isOpen ? /\n/g : ' ', ' ')}`}
            </Text>
          </Container>
          {allowReadMore && (
            <Pressable
              onPress={() => {
                setIsOpen(v => !v)
              }}
            >
              <Text style={styles.collapseText}>{t(`common:textCollapse.${isOpen ? 'showLess' : 'showMore'}`)}</Text>
            </Pressable>
          )}
        </View>

        <View style={styles.hr} />

        {![StatusEnum.EXPIRED, StatusEnum.ERROR, StatusEnum.CANCELED].includes(data!.status!) && (
          <>
            <View style={styles.headlineRow}>
              <Text style={styles.headline}>{t('common:invoiceAccept.selectDigitalCurrency')}</Text>
            </View>

            <View style={{ marginBottom: 8 }}>
              <Select
                fullWidth
                required
                label={t('common:forms.cryptoCurrency') as string}
                value={value}
                onChange={setValue}
                items={asSelect}
              />
            </View>
          </>
        )}

        {![StatusEnum.EXPIRED, StatusEnum.ERROR, StatusEnum.CANCELED].includes(data!.status!) && (
          <View style={styles.total} aria-label="payment-process-total">
            <View style={[styles.row, { justifyContent: 'space-between' }]}>
              <View style={{ flex: 1 }}>
                <Text style={{ ...FONTS.h3, textAlign: 'left' }}>
                  {t('common:forms.indicativePriceInCryptocurrency')}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={FONTS.h3}>
                  {result && crypto ? `≈ ${result?.amount ?? ''} ${crypto?.name ?? ''}` : ''}
                </Text>
                <Text
                  style={{
                    ...FONTS.h3,
                    fontWeight: '100',
                    marginLeft: 24,
                    fontFamily: FONTS.disc.fontFamily
                  }}
                >
                  {data?.fiatCurrencyVolume && data?.fiatCurrencyId && result
                    ? `${currency(data.fiatCurrencyVolume, findOne(data?.fiatCurrencyId)?.symbol)} ${
                        findOne(data?.fiatCurrencyId)?.name ?? ''
                      }`
                    : ' – '}{' '}
                </Text>
              </View>
            </View>
            {Boolean(result?.rate && crypto && data) && (
              <View>
                <Text style={styles.exchangeExample}>
                  <Text style={styles.asterisk}>*&nbsp;</Text>1 {crypto?.name}
                  {' ≈ '}
                  {result?.rate && crypto ? result.rate : ''} {findOne(data?.fiatCurrencyId)?.name}
                </Text>
              </View>
            )}
          </View>
        )}

        {![StatusEnum.EXPIRED, StatusEnum.ERROR, StatusEnum.CANCELED].includes(data!.status!) && (
          <>
            <View style={styles.cta}>
              <Button
                disabled={!crypto}
                onClick={() => {
                  if (data?.isKycRequired) {
                    navigate(`kyc?currency=${value}`)

                    return
                  }

                  if (value) {
                    updateInvoice({
                      variables: {
                        data: {
                          cryptoCurrencyId: Number(value)
                        },
                        uid: id!
                      }
                    }).then(() => {
                      navigate(`hedge?currency=${value}`)
                    })
                  }
                }}
              >
                {t('common:invoiceAccept.proceedToPayment')}
              </Button>
            </View>
          </>
        )}
      </View>

      {(data?.status === StatusEnum.ERROR ||
        data?.status === StatusEnum.EXPIRED ||
        data?.status === StatusEnum.CANCELED) && (
        <View style={{ marginTop: 20 }}>
          <ExclamationError />
          <View style={{ marginTop: 20 }}>
            {Boolean(data?.expiredType) && (
              <Text style={FONTS.textRegular}>{t(`common:saleExpireErrors.${data?.expiredType}`)}</Text>
            )}
            {Boolean(data?.errorType) && (
              <Text style={FONTS.textRegular}>{t(`common:saleApiErrors.${data?.errorType}`)}</Text>
            )}
            {data?.status === StatusEnum.CANCELED && (
              <Text style={FONTS.textRegular}>{t('common:checkout.cancelled')}</Text>
            )}
          </View>
        </View>
      )}
    </Paper>
  )
}

export default AcceptInvoiceForm
