import React, { FC } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Svg, { ClipPath, Defs, G, LinearGradient, Mask, Path, Rect, Stop } from 'react-native-svg-web'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import FileUploadWeb from '@cryptoswift/shared/src/components/controls/FileUpload/FileUploadWeb'
import * as Sentry from '@sentry/react'

import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  concat,
  from,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
// import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { API_URI /* WS_API_URI */ } from './api'
import DatePicker from './components/controls/DatePicker/DatePickerWeb'
import ImagePreviewWeb from './components/controls/ImagePreview/ImagePreviewWeb'
import SelectWeb from './components/controls/Select/SelectWeb'
import { Tooltip } from './components/TooltipWeb'
import EmailConfirm from './containers/Login/EmailConfirm'
import ResetPassword from './containers/Login/ResetPassword'
import AcceptInvoiceForm from './containers/Payments/InvoiceAccept/AcceptInvoiceForm'
import InvoiceAcceptPaymentProcess from './containers/Payments/InvoiceAccept/InvoiceAcceptPaymentProcess'
import WebCheckHedge from './containers/Payments/InvoiceAccept/WebCheckHedge'
import WebKycCheck from './containers/Payments/InvoiceAccept/WebKycCheck'
import WebOutlet from './containers/Payments/InvoiceAccept/WebOutlet'
import i18nDef from './locales'
import { ComponentsProvider } from './providers/Components'
import NoMatch from './providers/NoMatch'
import { getEnv } from './utils/getEnvBrowser'

i18nDef.changeLanguage('en')

Sentry.init({
  dsn: 'https://0ef8c1b38e42bbb9759173d542249407@o4505507149840384.ingest.sentry.io/4505640683372544',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const env = getEnv()

function findError(obj: Record<string, any>) {
  if (obj && obj.constructor === Object) {
    const nestedError = Object.values(obj)
      .map(value => findError(value))
      .find(value => value !== null) as any

    if (nestedError) return nestedError

    if (obj.__typename === 'Error') return obj
  }

  return null
}

const catchErrorMiddleware: ApolloLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    const error = findError(data)

    if (error) {
      throw new ApolloError({ errorMessage: error.errorMessage || error.errorMsg || 'Server Error', extraInfo: error })
    }

    return data
  })
})

const httpLink = new HttpLink({
  uri: API_URI.replace('{env}', env)
})

// const wsLink = new WebSocketLink({
//   uri: WS_API_URI.replace('{env}', env),
// })

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  // wsLink,
  from([concat(catchErrorMiddleware, httpLink)])
)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([concat(catchErrorMiddleware, httpLink)])
})

const webComponents = new Map()

webComponents.set('select', SelectWeb)
webComponents.set('date', DatePicker)
webComponents.set('radio', () => <div />)
webComponents.set('svg', Svg)
webComponents.set('path', Path)
webComponents.set('g', G)
webComponents.set('mask', Mask)
webComponents.set('defs', Defs)
webComponents.set('clipPath', ClipPath)
webComponents.set('rect', Rect)
webComponents.set('linearGradient', LinearGradient)
webComponents.set('stop', Stop)
webComponents.set('file', FileUploadWeb)
webComponents.set('imagePreview', ImagePreviewWeb)
webComponents.set('tooltip', Tooltip)

const Layout: FC = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
}

export function AppWeb(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <ComponentsProvider components={webComponents}>
        <SafeAreaView style={styles.root}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="invoice/:id" element={<WebOutlet />}>
                  <Route index element={<AcceptInvoiceForm />} />
                  <Route path="kyc" element={<WebKycCheck />} />
                  <Route path="hedge" element={<WebCheckHedge />} />
                  <Route path="process" element={<InvoiceAcceptPaymentProcess />} />
                </Route>
                <Route path="confirm-email" element={<EmailConfirm />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="*" element={<NoMatch />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SafeAreaView>
      </ComponentsProvider>
    </ApolloProvider>
  )
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  }
})
