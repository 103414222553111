import React, { FC } from 'react'
import { Animated, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

import useAnimatedAppear from '../hooks/utils/useAnimatedAppear'
import { BACKGROUNDS } from '../styles'

const Paper: FC<
  {
    style?: ViewStyle
    animate?: boolean
    children?: React.ReactNode
  } & ViewProps
> = ({ children, style, animate = false, ...rest }) => {
  const animatedAppear = useAnimatedAppear()

  if (animate) {
    return (
      <Animated.View {...rest} style={[styles.card, style, animatedAppear] as StyleProp<ViewStyle>}>
        {children}
      </Animated.View>
    )
  }

  return (
    <View {...rest} style={[styles.card, style]}>
      {children}
    </View>
  )
}

export default Paper

const styles = StyleSheet.create({
  card: {
    minWidth: 708,
    paddingTop: 64,
    paddingRight: 64,
    paddingLeft: 64,
    paddingBottom: 64,
    backgroundColor: BACKGROUNDS.bg4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginRight: 164,
    marginLeft: 164
  }
})
