import React, { FC, ReactNode, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { yupResolver } from '@hookform/resolvers/yup'
import { captureException } from '@sentry/react'
import * as yup from 'yup'

import { CheckStatusEnum } from '../../../__generated__/globalTypes'
import Button from '../../../components/controls/Button'
import Camera from '../../../components/icons/Utility/Camera'
import BackdropModal from '../../../components/Modal'
import Paper from '../../../components/Paper'
import { CheckKyc } from '../../../gql/mutations/__generated__/CheckKyc'
import { useKYC } from '../../../hooks/api/useKYC'
import i18nDef from '../../../locales'
import { BACKGROUNDS, COLORS, FONTS } from '../../../styles'
import KycErrorMessage from '../KycErrorMessage'

const CLIENT_NAME_ERROR_MESSAGE = 'Client name does not match the name on the document'

const DOCUMENT_TYPES = {
  ID: 'ID',
  IBAN: 'IBAN',
  CHEQUE: 'CHEQUE',
  TAX_SHEET: 'TAX_SHEET',
  PAY_SLIP: 'PAY_SLIP',
  ADDRESS_PROOF: 'ADDRESS_PROOF',
  CREDIT_CARD: 'CREDIT_CARD',
  PORTRAIT: 'PORTRAIT',
  LEGAL_ENTITY: 'LEGAL_ENTITY',
  CAR_REGISTRATION: 'CAR_REGISTRATION',
  LIVENESS: 'LIVENESS'
}

const schema = yup
  .object({
    documentType: yup.string().required(i18nDef.t('common:forms.documentTypeIsRequired') as string)
  })
  .required()

const KycProcess: FC<{
  containerClickMakePicture?: boolean
  onCompleted: (kyc: CheckKyc) => void
  pickFile: (cb: (f: Record<string, any>) => void) => void
  credentials: string | Record<string, string>
  url: string
  confirm?: boolean
  clientName: string
  children?: ReactNode
  clearable?: boolean
  isAllowed?: boolean
}> = ({
  containerClickMakePicture = false,
  clearable = false,
  children,
  onCompleted,
  pickFile,
  credentials,
  url,
  confirm = false,
  clientName,
  isAllowed = true
}) => {
  const { t } = useTranslation()
  const [isVerifiedCustomer, setIsVerifiedCustomer] = useState(false)
  const [kycErrorMetadata, setKycErrorMetadata] = useState<{ title: string; description: string } | null>(null)

  const { checkKyc, isChecking } = useKYC(url, credentials, clientName)
  const form = useForm({
    defaultValues: {
      documentType: DOCUMENT_TYPES.ID
    },
    resolver: yupResolver(schema)
  })
  const customerData = useRef<any>(null)
  const [image, setImage] = useState<Record<string, any> | null>(null)
  const onChange = (image: Record<string, any>) => {
    setImage(image)
  }

  const pickFileSource = () => {
    if (!isAllowed) return

    if (image && clearable) {
      setImage(null)

      return
    }

    pickFile(f => {
      onChange(f)
    })
  }

  const closeModal = () => setKycErrorMetadata(null)

  return (
    <>
      <BackdropModal visible={Boolean(kycErrorMetadata)} onRequestClose={closeModal}>
        <KycErrorMessage
          title={kycErrorMetadata?.title ?? ''}
          description={kycErrorMetadata?.description ?? ''}
          onComplete={closeModal}
        />
      </BackdropModal>
      <Paper
        animate
        style={{
          paddingBottom: 40,
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 40,
          minWidth: 510,
          maxWidth: 510
        }}
        aria-label="kyc-paper"
      >
        {isVerifiedCustomer ? (
          <>
            <Text style={{ ...FONTS.h2, textAlign: 'center' }}>{t('common:paymentProcess.kycVerification')}</Text>
            <View style={styles.hr} />
            <Text
              style={{
                ...FONTS.textMedium,
                textAlign: 'center',
                color: COLORS.primaryViolet,
                textTransform: 'uppercase',
                marginBottom: 8
              }}
            >
              {t('common:paymentProcess.confirmPerson')}
            </Text>
            <View style={{ marginBottom: 8, marginTop: 8 }} />
            {Boolean(image) && (
              <View>
                <View style={styles.previewContainer}>
                  <Image
                    style={[styles.imagePreview]}
                    source={{ uri: `data:image/png;base64,${(image as any)?.data}` }}
                  />
                </View>
              </View>
            )}
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1, marginRight: 4 }}>
                <Button
                  light
                  onClick={() => {
                    setIsVerifiedCustomer(false)
                    setImage(null)
                    customerData.current = null
                  }}
                >
                  {t('common:checkout.cancel')}
                </Button>
              </View>
              <View style={{ flex: 1, marginLeft: 4 }}>
                <Button
                  onClick={() => {
                    onCompleted(customerData.current!)
                  }}
                >
                  {t('common:checkout.confirmPerson')}
                </Button>
              </View>
            </View>
          </>
        ) : (
          <>
            <Text style={{ ...FONTS.h2, textAlign: 'center' }}>{t('common:paymentProcess.kycVerification')}</Text>
            <View style={styles.hr} />
            <Text
              style={{
                ...FONTS.textMedium,
                textAlign: 'center',
                color: COLORS.primaryViolet,
                textTransform: 'uppercase',
                marginBottom: 8
              }}
            >
              {t('common:paymentProcess.checkScanQuality')}
            </Text>
            <Text
              style={{
                ...FONTS.disc,
                textAlign: 'center',
                color: COLORS.primaryBlack
              }}
            >
              {t('common:paymentProcess.idSeenClearly')}
            </Text>
            <View style={{ marginBottom: 8, marginTop: 8 }} />
            {Boolean(image) && (
              <View>
                <View style={styles.previewContainer}>
                  <Image
                    style={[styles.imagePreview]}
                    source={{ uri: `data:image/png;base64,${(image as any)?.data}` }}
                  />
                </View>
              </View>
            )}

            <TouchableOpacity
              activeOpacity={containerClickMakePicture ? 0.75 : 1}
              onPress={containerClickMakePicture ? pickFileSource : undefined}
              style={{
                display: Boolean(image) ? 'none' : 'flex'
              }}
            >
              <View
                style={[styles.previewContainer, { alignItems: 'center', width: '100%', justifyContent: 'center' }]}
              >
                <View
                  style={[
                    styles.imagePreview,
                    { backgroundColor: BACKGROUNDS.bg2, width: '100%', alignItems: 'center', justifyContent: 'center' }
                  ]}
                >
                  {children}
                  <Camera />
                  <Text style={[FONTS.disc, { marginTop: 16, color: COLORS.primaryViolet, textAlign: 'center' }]}>
                    {isAllowed
                      ? t('common:paymentProcess.makePhotoOfDocument')
                      : t('common:paymentProcess.makeSureCameraEnabled')}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>

            {isAllowed && (
              <>
                <View style={{ marginBottom: 8 }}>
                  <Button disabled={isChecking} onClick={pickFileSource} light={!isChecking}>
                    {image
                      ? t('common:paymentProcess.kycVerificationScanNew')
                      : t('common:paymentProcess.kycVerificationScan')}
                  </Button>
                </View>

                <View>
                  <Button
                    onClick={() => {
                      form.handleSubmit(values => {
                        if (!image) {
                          throw new Error('No Image Provided')
                        }

                        checkKyc((image as Record<string, any>)?.data ?? '', values.documentType as unknown as string)
                          .then(result => {
                            if (!result) {
                              throw new Error('Image Processing Error')
                            }

                            if (result?.data?.checkKyc?.lastReport.globalStatus !== CheckStatusEnum.ERROR) {
                              customerData.current = result!.data!

                              if (confirm) {
                                setIsVerifiedCustomer(true)
                              } else {
                                onCompleted(customerData.current)
                              }
                              return
                            }

                            if (result?.data?.checkKyc?.lastReport.globalStatus === CheckStatusEnum.ERROR) {
                              // eslint-disable-next-line prefer-const
                              let message = ''

                              result?.data?.checkKyc?.lastReport?.checks
                                ?.filter(c => c.status === CheckStatusEnum.ERROR)
                                ?.forEach(c => {
                                  const subCheckItems =
                                    c.subChecks?.filter(subCheck => subCheck.status === CheckStatusEnum.ERROR) ?? []

                                  message += c.message + (subCheckItems.length !== 0 ? ':' : '') + '\n'

                                  subCheckItems?.forEach(subCheck => {
                                    message += ' · ' + subCheck.message + '\n'
                                  })
                                })

                              setKycErrorMetadata({
                                title: t('common:kycErrorModal.title', { context: 'general' }),
                                description: message.slice(0, -1)
                              })
                            }
                          })
                          .catch(error => {
                            if (error?.message === CLIENT_NAME_ERROR_MESSAGE) {
                              setKycErrorMetadata({
                                title: t('common:kycErrorModal.title', { context: 'nameMismatch' }),
                                description: t('common:kycErrorModal.error.nameMismatch', {
                                  context: Platform.OS === 'web' ? 'client' : 'merchant'
                                })
                              })
                            } else {
                              setKycErrorMetadata({
                                title: t('common:kycErrorModal.title', { context: 'general' }),
                                description: t('common:kycErrorModal.error.unexpected')
                              })
                              captureException({ title: 'Document validation error', error })
                            }
                          })
                      })()
                    }}
                    disabled={isChecking || !image}
                    isLoading={isChecking}
                  >
                    {t(
                      isChecking
                        ? 'common:paymentProcess.kycVerificationChecking'
                        : 'common:paymentProcess.kycVerificationSubmit'
                    )}
                  </Button>
                </View>
              </>
            )}

            <View>
              <Text style={styles.kycRulesAccept}>{t('common:paymentProcess.kycRulesAccept')}</Text>
            </View>
          </>
        )}
      </Paper>
    </>
  )
}

export default KycProcess

const styles = StyleSheet.create({
  hr: {
    borderColor: '#E7ECF3',
    borderBottomWidth: 1,
    marginTop: 16,
    marginBottom: 16
  },
  previewContainer: {
    paddingHorizontal: 26,
    paddingVertical: 32,
    backgroundColor: BACKGROUNDS.bg4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    marginBottom: 16
  },
  imagePreview: {
    height: 240
  },
  apiError: {
    ...FONTS.h5,
    color: COLORS.error,
    marginBottom: 10,
    textAlign: 'center'
  },
  kycRulesAccept: {
    ...FONTS.disc,
    color: COLORS.primaryViolet,
    paddingHorizontal: 60,
    textAlign: 'center',
    marginTop: 12
  }
})
