/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AMLStatusEnum {
  ERROR = "ERROR",
  OK = "OK",
  PENDING = "PENDING",
  RISKY = "RISKY",
  VALIDATING = "VALIDATING",
}

export enum BlockchainEnum {
  BITCOIN = "BITCOIN",
  ETHEREUM = "ETHEREUM",
  TRON = "TRON",
}

export enum BlockchainTransactionStatusEnum {
  CONFIRMED = "CONFIRMED",
  DROPPED = "DROPPED",
  HAS_CONFIRMATIONS = "HAS_CONFIRMATIONS",
  PENDING = "PENDING",
}

export enum CheckStatusEnum {
  ERROR = "ERROR",
  NONE = "NONE",
  OBSOLETE = "OBSOLETE",
  OK = "OK",
  WARN = "WARN",
}

export enum ErrorTypeEnum {
  AML_CHECK_ERROR = "AML_CHECK_ERROR",
  DEPOSIT_KRAKEN_ERROR = "DEPOSIT_KRAKEN_ERROR",
  DROPPED = "DROPPED",
  HEDGING_ERROR = "HEDGING_ERROR",
  P3_INTERNAL_ERROR = "P3_INTERNAL_ERROR",
  RISKY = "RISKY",
  SELL_ERROR = "SELL_ERROR",
  SWAP_KRAKEN_ERROR = "SWAP_KRAKEN_ERROR",
  WITHDRAWAL_KRAKEN_ERROR = "WITHDRAWAL_KRAKEN_ERROR",
}

export enum ExpiredTypeEnum {
  INVOICE_EXPIRED = "INVOICE_EXPIRED",
  PAYMENT_EXPIRED = "PAYMENT_EXPIRED",
}

export enum HedgingStatusEnum {
  DONE = "DONE",
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}

export enum SaleTypeEnum {
  INVOICE = "INVOICE",
  SALE = "SALE",
}

export enum StatusEnum {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
}

export interface CompanyInput {
  name: string;
  currencyId: number;
  logoUrl?: string | null;
}

export interface CompanyUpdateInput {
  name?: string | null;
  currencyId?: number | null;
  logoUrl?: string | null;
}

export interface CreateInvoiceInput {
  fiatCurrencyId: number;
  fiatCurrencyVolume: string;
  customerName: string;
  customerEmail: string;
  customerPhone?: string | null;
  description: string;
  isKycRequired: boolean;
  invoiceExpirationDate: any;
  urlsToFiles?: string[] | null;
}

export interface CreateSaleInput {
  fiatCurrencyId: number;
  cryptoCurrencyId: number;
  fiatCurrencyVolume: string;
  customerName: string;
  customerEmail?: string | null;
  customerPhone?: string | null;
  description: string;
  isKycRequired: boolean;
  kycId?: any | null;
  urlsToFiles?: string[] | null;
}

export interface FiatCryptoInput {
  amount: string;
  cryptoId: number;
  fiatId: number;
}

export interface UpdateInvoiceInput {
  cryptoCurrencyId?: number | null;
  kycId?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
