import { gql } from '@apollo/client'

export const CURRENCY_FRAGMENT = gql`
  fragment CurrencyFragment on Currency {
    blockchain
    color
    id
    isEnabled
    isFiat
    isStablecoin
    name
    precision
    symbol
    symbolPrecision
    maxUsdAmount
    minUsdAmount
  }
`
