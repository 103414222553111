import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const ExclamationError: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const Rect = useComponent('rect')

  return (
    <Svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect width={40} height={40} rx={10.667} fill="#FB5555" fillOpacity={0.1} />
      <Rect x={10} y={10} width={20} height={20} rx={6.222} fill="#FB5555" />
      <Path d="M19 21.75V15h1.5v6.75H19Zm0 3.75V24h1.5v1.5H19Z" fill="#fff" />
    </Svg>
  )
}

export default ExclamationError
