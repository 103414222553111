import { useCallback, useRef, useState } from 'react'
import { LayoutChangeEvent, NativeSyntheticEvent, ScrollView, TextLayoutEventData, TextStyle, View } from 'react-native'

const OVERSIGHT = 4

export function getWidthOfText(txt: string, fontname: string, fontsize: string) {
  const e = document.createElement('span')
  e.style.position = 'absolute'
  e.style.left = '-9999px'
  e.style.whiteSpace = 'nowrap'
  e.style.fontFamily = fontname
  e.style.fontSize = fontsize
  document.body.appendChild(e)

  e.textContent = txt
  const width = e.offsetWidth

  document.body.removeChild(e)

  return width
}

export function analyzeHTMLContent(innerHTMLContent: string, fontname: string, fontsize: string) {
  const lines = innerHTMLContent.split('\n')
  const numLines = lines.length
  let longestLineWidth = 0

  if (numLines > 1) {
    return {
      nativeEvent: {
        lines: Array.from({ length: lines.length })
      }
    }
  }

  for (const line of lines) {
    const lineWidth = getWidthOfText(line, fontname, fontsize)
    if (lineWidth > longestLineWidth) {
      longestLineWidth = lineWidth
    }
  }

  return {
    nativeEvent: {
      lines: Array.from({ length: lines.length }, _ => ({ width: longestLineWidth }))
    }
  }
}

export const useReadMore = () => {
  const viewWidth = useRef<null | number>(null)
  const textWidth = useRef<null | number>(null)
  const [initialReadMoreSet, setInitialReadMore] = useState(false)

  const [isOpen, setIsOpen] = useState(true)
  const [allowReadMore, setAllowReadMore] = useState(false)

  const onChange = useCallback(() => {
    if (initialReadMoreSet) return

    if (viewWidth.current !== null && textWidth.current !== null) {
      setAllowReadMore(viewWidth.current <= textWidth.current - OVERSIGHT)
      setInitialReadMore(true)
      setIsOpen(false)
    }
  }, [initialReadMoreSet])

  const onViewLayout = useCallback(
    (e: LayoutChangeEvent) => {
      viewWidth.current = e?.nativeEvent.layout?.width ?? 0

      onChange()
    },
    [onChange]
  )

  const onTextLayout = useCallback(
    (e: NativeSyntheticEvent<TextLayoutEventData>) => {
      if (e?.nativeEvent.lines.length > 1) {
        textWidth.current = 9999
      } else {
        textWidth.current = e?.nativeEvent.lines.reduce((acc, next) => Math.max(next.width, acc), 0)
      }

      onChange()
    },
    [onChange]
  )

  return {
    onTextLayout,
    onViewLayout,
    isOpen,
    setIsOpen,
    allowReadMore,
    textStyle: (initialReadMoreSet ? undefined : { height: 0 }) as TextStyle,
    Container: isOpen ? ScrollView : View
  }
}
