import * as React from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'
const CopyBlackLarge = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={21} fill="none">
      <Path
        fill="#121527"
        d="M11.066 21h-7.54A3.161 3.161 0 0 1 .37 17.842V7.145a3.161 3.161 0 0 1 3.158-3.158h7.539a3.161 3.161 0 0 1 3.158 3.158v10.697A3.161 3.161 0 0 1 11.066 21ZM3.526 5.566a1.58 1.58 0 0 0-1.578 1.579v10.697c0 .87.708 1.579 1.579 1.579h7.539a1.58 1.58 0 0 0 1.579-1.579V7.145a1.58 1.58 0 0 0-1.579-1.58h-7.54Zm13.856 10.302V3.948A3.161 3.161 0 0 0 14.224.788H5.46a.79.79 0 1 0 0 1.58h8.763c.87 0 1.579.708 1.579 1.578v11.921a.79.79 0 1 0 1.579 0Z"
      />
    </Svg>
  )
}
export default CopyBlackLarge
