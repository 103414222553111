import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

import Paper from '../../../components/Paper'
import Button from '../../../components/controls/Button'
import ExclamationError from '../../../components/icons/Utility/ExclamationError'
import { FONTS } from '../../../styles'

const WebInvoiceAttentionMessage: FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const { t } = useTranslation()

  return (
    <Paper
      style={{
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        minWidth: 510,
        width: 510
      }}
      animate
      aria-label="notofication-modal"
    >
      <View>
        <View style={styles.row}>
          <View>
            <ExclamationError />
          </View>
          <Text style={styles.text}>{t('common:saleAttention.attention')}</Text>
        </View>
        <Text style={styles.description}>{t('common:saleAttention.message', { context: 'invoice'})}</Text>
        {Boolean(onComplete) && (
          <View style={{ marginTop: 24 }}>
            <Button onClick={onComplete}>
              <Text>{t('common:saleAttention.proceed')}</Text>
            </Button>
          </View>
        )}
      </View>
    </Paper>
  )
}

export default memo(WebInvoiceAttentionMessage)

const styles = StyleSheet.create({
  text: {
    ...FONTS.h2,
    marginLeft: 24
  },
  row: {
    flexDirection: 'row',
    marginBottom: 24
  },
  description: {
    ...FONTS.h4
  }
})
