import React, { FC } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const ArrowRoundDown: FC<{ transparent?: boolean; style?: StyleProp<ViewStyle> }> = ({
  transparent = false,
  style
}) => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const Rect = useComponent('rect')

  return (
    <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <Rect width={24} height={24} rx={12} fill={transparent ? 'none' : '#F2F3F7'} />
      <Path
        d="M16.72 9.28a.943.943 0 0 0-1.338 0l-3.406 3.4-3.358-3.4a.943.943 0 0 0-1.545.312.971.971 0 0 0 .208 1.051l4.022 4.073a.946.946 0 0 0 1.038.21.947.947 0 0 0 .309-.21l4.07-4.073a.961.961 0 0 0 .28-.682.97.97 0 0 0-.28-.682Z"
        fill="#606690"
      />
    </Svg>
  )
}

export default ArrowRoundDown
