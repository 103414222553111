import React, { FC } from 'react'
import { Image, ImageSourcePropType, Text, View } from 'react-native'
import { Navigate, useParams } from 'react-router-dom'
import omitDeep from 'omit-deep-lodash'

import { useQuery } from '@apollo/client'

import { CreateSaleInput, StatusEnum } from '../../../__generated__/globalTypes'
import {
  GetUserCompanyByUserId,
  GetUserCompanyByUserIdVariables
} from '../../../gql/queries/__generated__/GetUserCompanyByUserId'
import { GET_COMPANY_BY_USER_ID } from '../../../gql/queries/getCompanyByUserId'
import { useSale } from '../../../hooks/api/useSale'
import PaymentProcess from '../PaymentProcess'

import styles from './styles'

const InvoiceAcceptPaymentProcess: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { result: data, refetch: refetchSale, error: saleError } = useSale(id)
  const { data: userCompany } = useQuery<GetUserCompanyByUserId, GetUserCompanyByUserIdVariables>(
    GET_COMPANY_BY_USER_ID,
    {
      skip: !data,
      variables: {
        userId: data?.userId
      }
    }
  )

  const company = {
    name: '',
    logoUrl: '',
    ...userCompany?.getUserCompanyByUserId
  }

  if (
    data?.status === StatusEnum.ERROR ||
    data?.status === StatusEnum.EXPIRED ||
    data?.status === StatusEnum.CANCELED
  ) {
    return <Navigate to={`/invoice/${id}`} replace />
  }

  return Boolean(data) && data?.uid && data?.paymentProcessStartDate && data?.cryptoCurrencyId ? (
    <View aria-label="payment-process-paper-container">
      <PaymentProcess data={omitDeep(data, '__typename') as CreateSaleInput} existingSaleId={data?.uid}>
        <View style={styles.brandingContainer}>
          <Image style={styles.logo} source={{ uri: company?.logoUrl } as ImageSourcePropType} />
          <Text style={styles.branding} numberOfLines={1} ellipsizeMode="tail">
            {company?.name?.slice(0, 40)}
            {company ? (company.name.length > 40 ? '...' : '') : ''}
          </Text>
        </View>
      </PaymentProcess>
    </View>
  ) : null
}

export default InvoiceAcceptPaymentProcess
