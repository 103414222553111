import { useCallback, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { SelectOption } from '../../components/controls/Select/types'
import { Currencies, CurrenciesVariables } from '../../gql/queries/__generated__/Currencies'
import { CURRENCIES } from '../../gql/queries/currencies'
import { upperFirst } from '../../utils/formatters'

export const useCurrency = ({ fiatOnly = false, cryptoOnly = false } = {}) => {
  const { data, loading } = useQuery<Currencies, CurrenciesVariables>(CURRENCIES, {
    variables: {
      fiatOnly,
      cryptoOnly
    },
    fetchPolicy: 'cache-and-network'
  })

  const currencies = useMemo(() => data?.currencies?.currencies ?? [], [data?.currencies?.currencies])

  const findOne = useCallback((id?: number) => currencies?.find(c => String(c.id) === String(id)), [currencies])

  return {
    findOne,
    asSelect: currencies.map(c => ({
      label: [c.name, upperFirst(c?.blockchain ?? '')].filter(Boolean).join(', '),
      key: String(c.id),
      id: c.id,
      value: String(c.id)
    })) as SelectOption[],
    currencies,
    loading
  }
}
