import { TextStyle } from 'react-native'

export const COLORS = {
  primaryBlack: '#121527',
  primaryDarkGray: '#343640',
  primaryPeach: '#BEC6F6',
  primaryDarkBlue: '#0F1437',
  primaryViolet: '#606690',
  primaryMilk: '#DFE2F6',
  error: '#FB5555'
}

export const BACKGROUNDS = {
  bg1: '#D9DBE8',
  bg2: '#F2F3F7',
  bg3: '#F6F6F9',
  bg4: '#FCFCFD',
  bg5: '#FDFDFD'
}

export const ADDITIONAL_COLORS = {
  red: '#FB5555',
  brightViolet: '#C0BBFF',
  mandarin: '#F9A779',
  barbiePink: '#FE72E8',
  green: '#50A371'
}

export const FONTS: Record<string, TextStyle> = {
  h1: {
    fontFamily: 'DINPro-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 34,
    lineHeight: 44.2,
    color: COLORS.primaryBlack
  },
  h2: {
    fontFamily: 'DINPro-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 28,
    lineHeight: 36.4,
    color: COLORS.primaryBlack
  },
  h3: {
    fontFamily: 'DINPro-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 21,
    lineHeight: 29.4,
    color: COLORS.primaryBlack
  },
  h4: {
    fontFamily: 'DINPro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 21,
    lineHeight: 29.4,
    color: COLORS.primaryBlack
  },
  sub: {
    fontFamily: 'DINPro',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 25.2,
    color: COLORS.primaryBlack
  },
  textBold: {
    fontFamily: 'DINPro-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 22.4,
    color: COLORS.primaryBlack
  },
  textMedium: {
    fontFamily: 'DINPro-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 22.4,
    color: COLORS.primaryBlack
  },
  textRegular: {
    fontFamily: 'DINPro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 22.4,
    color: COLORS.primaryBlack
  },
  disc: {
    fontFamily: 'DINPro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 19.6,
    color: COLORS.primaryBlack
  }
}
