import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { Animated, Easing, StyleSheet, Text, TextStyle, TouchableHighlight, View, ViewStyle } from 'react-native'

import { COLORS, FONTS } from '../../styles'
import Loader from '../icons/Utility/Loader'

const useInfiniteRotation = (duration = 1000) => {
  const rotation = useRef(new Animated.Value(0)).current

  useEffect(() => {
    const startRotation = () => {
      rotation.setValue(0)
      Animated.timing(rotation, {
        toValue: 1,
        duration,
        easing: Easing.linear,
        useNativeDriver: true
      }).start(() => {
        startRotation()
      })
    }

    startRotation()
  }, [duration])

  return rotation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })
}

const Button: FC<{
  onClick: () => void
  style?: ViewStyle
  textStyle?: TextStyle
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  disabled?: boolean
  light?: boolean
  children?: ReactNode
  isLoading?: boolean
}> = ({
  onClick,
  textStyle,
  style,
  startAdornment = null,
  endAdornment = null,
  children,
  disabled = false,
  light = false,
  isLoading = false
}) => {
  const spin = useInfiniteRotation()

  return (
    <TouchableHighlight
      activeOpacity={(light ? 0.5 : undefined) as number}
      underlayColor={light ? COLORS.primaryMilk : COLORS.primaryDarkGray}
      onPress={!disabled && !isLoading ? onClick : () => null}
      style={[styles.button, style, light && styles.buttonLight]}
    >
      <View style={[styles.buttonView, disabled && styles.disabled]}>
        {isLoading ? (
          <Animated.View style={{ transform: [{ rotate: spin }] }}>
            <Loader />
          </Animated.View>
        ) : (
          startAdornment
        )}
        <Text style={[styles.buttonText, textStyle, light && styles.buttonLightText]}>{children}</Text>
        {endAdornment}
      </View>
    </TouchableHighlight>
  )
}

export default Button

const styles = StyleSheet.create({
  button: {
    backgroundColor: COLORS.primaryDarkBlue,
    width: '100%'
  },
  buttonView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    ...FONTS.textMedium,
    color: '#ffffff',
    textTransform: 'uppercase',
    paddingTop: 12,
    paddingBottom: 12,
    marginRight: 16,
    marginLeft: 16
  },
  disabled: {
    backgroundColor: '#7D7D7D'
  },
  buttonLight: {
    backgroundColor: COLORS.primaryMilk
  },
  buttonLightText: {
    color: COLORS.primaryBlack
  }
})
