import { gql } from '@apollo/client'

import { CURRENCY_FRAGMENT } from '../fragments/CurrencyFragment'

export const CURRENCIES = gql`
  query Currencies($fiatOnly: Boolean = false, $cryptoOnly: Boolean = false) {
    currencies(fiatOnly: $fiatOnly, cryptoOnly: $cryptoOnly) {
      currencies {
        ...CurrencyFragment
      }
      error {
        errorMsg
      }
    }
  }

  ${CURRENCY_FRAGMENT}
`
