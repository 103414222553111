import { useCallback, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'

import { CheckKyc, CheckKycVariables } from '../../gql/mutations/__generated__/CheckKyc'
import { CHECK_KYC } from '../../gql/mutations/checkKyc'
import { GET_KYC } from '../../gql/queries/getKyc'

export const useKYC = (serviceApiUrl: string, credentials: string | Record<string, string>, clientName: string) => {
  const [isChecking, setIsChecking] = useState(false)

  const checkDocument = useCallback(
    async (base64EncodedImage: string, documentType: string) => {
      const url = `${serviceApiUrl}/upload/id-now/document/`

      const reqBody = {
        images: [{ data: base64EncodedImage }],
        type: documentType
      }

      const reqHeaders = {
        'Content-Type': 'application/json'
      }

      if (typeof credentials === 'string') {
        ;(reqBody as any).invoiceId = credentials
      } else {
        Object.assign(reqHeaders, credentials)
      }

      const reqOptions = {
        method: 'POST',
        headers: reqHeaders,
        body: JSON.stringify(reqBody)
      }

      const response = await fetch(url, reqOptions)

      if (response.ok) {
        const { uid } = await response.json()

        if (uid) {
          return uid
        } else {
          throw new Error((response as any)?.detail ?? 'Document check error')
        }
      } else {
        const { detail } = await response.json()

        throw new Error(detail ?? 'Document check error')
      }
    },
    [credentials]
  )

  const [checkKyc, { error }] = useMutation<CheckKyc, CheckKycVariables>(CHECK_KYC, {
    onCompleted: () => {
      setIsChecking(false)
    }
  })

  const [getKyc] = useLazyQuery(GET_KYC)

  const verifyKyc = useCallback(
    async (base64EncodedImage: string, documentType: string) => {
      setIsChecking(true)

      let documentCheckUid = null

      try {
        documentCheckUid = await checkDocument(base64EncodedImage, documentType)
      } catch (e) {
        setIsChecking(false)

        throw new Error('Server Error')
      }

      if (documentCheckUid) {
        return checkKyc({ variables: { kycId: documentCheckUid, clientName: clientName } }).finally(() => {
          setIsChecking(false)
        })
      }
    },
    [checkDocument, clientName]
  )

  return {
    checkKyc: verifyKyc,
    getKyc,
    isChecking,
    checkKycError: error
  }
}
