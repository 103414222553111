import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewStyle } from 'react-native'

import { HedgingStatusEnum, StatusEnum } from '../../../__generated__/globalTypes'
import { Currencies_currencies_currencies } from '../../../gql/queries/__generated__/Currencies'
import { Sale_sale_sale } from '../../../gql/queries/__generated__/Sale'

import PaymentProcessStep, { ProcessStepStatus } from './PaymentProcessStep'

const StatusesList: FC<{
  data?: Sale_sale_sale | null
  currentCrypto?: Currencies_currencies_currencies | null
  style?: ViewStyle
  itemStyle?: ViewStyle
}> = ({ data: saleData, currentCrypto, style, itemStyle }) => {
  const cryptoName = currentCrypto?.name ?? 'Crypto'
  const { t } = useTranslation()

  return (
    <View style={style}>
      <PaymentProcessStep
        style={itemStyle}
        title={t('common:statusesList.cryptoDetected') as string}
        isDisabled={!saleData?.isFullyPaid}
        status={saleData?.isFullyPaid ? ProcessStepStatus.Done : ProcessStepStatus.Pending}
        pendingText={t('common:statusesList.waitingFor', { cryptoName }) as string}
        successText={t('common:statusesList.received', { cryptoName }) as string}
        tooltipText={t('common:statusesList.cryptoDetectedTooltip') as string}
      />
      <PaymentProcessStep
        style={itemStyle}
        title={t('common:statusesList.amlCheck') as string}
        isDisabled={!saleData?.isFullyPaid}
        status={saleData?.isAmlCompleted ? ProcessStepStatus.Done : ProcessStepStatus.Pending}
        pendingText={t('common:statusesList.pending') as string}
        successText={t('common:statusesList.completed') as string}
        tooltipText={t('common:statusesList.amlCheckTooltip') as string}
      />
      {Boolean(saleData?.isHedgingEnabled) ? (
        <>
          <PaymentProcessStep
            style={itemStyle}
            title={t('common:statusesList.hedging', { cryptoName }) as string}
            isDisabled={!saleData?.isFullyPaid}
            status={
              saleData?.hedgingStatus === HedgingStatusEnum.DONE ? ProcessStepStatus.Done : ProcessStepStatus.Pending
            }
            pendingText={t('common:statusesList.pending') as string}
            successText={t('common:statusesList.completed') as string}
            tooltipText={t('common:statusesList.hedgingTooltip') as string}
          />
        </>
      ) : (
        <PaymentProcessStep
          style={itemStyle}
          title={t('common:statusesList.cryptoSold') as string}
          isDisabled={!saleData?.isFullyPaid}
          status={
            saleData?.isKrakenDeposited
              ? saleData?.isCryptoSold
                ? ProcessStepStatus.Done
                : ProcessStepStatus.Pending
              : ProcessStepStatus.Inactive
          }
          pendingText={t('common:statusesList.selling', { cryptoName }) as string}
          successText={t('common:statusesList.completed') as string}
          tooltipText={t('common:statusesList.cryptoSoldTooltip') as string}
        />
      )}
      <PaymentProcessStep
        style={itemStyle}
        title={t('common:statusesList.movingCrypto') as string}
        isDisabled={!saleData?.isFullyPaid}
        status={
          saleData?.isHedgingEnabled
            ? saleData?.hedgingStatus !== HedgingStatusEnum.DONE
              ? ProcessStepStatus.Inactive
              : saleData?.isKrakenDeposited
              ? ProcessStepStatus.Done
              : ProcessStepStatus.Pending
            : saleData?.isKrakenDeposited
            ? ProcessStepStatus.Done
            : ProcessStepStatus.Pending
        }
        pendingText={t('common:statusesList.pending') as string}
        successText={t('common:statusesList.completed') as string}
        tooltipText={t('common:statusesList.movingCryptoTooltip') as string}
      />
      <PaymentProcessStep
        style={itemStyle}
        title={t('common:statusesList.sentToYourBank') as string}
        isDisabled={!saleData?.isFullyPaid}
        status={
          saleData?.isHedgingEnabled
            ? saleData?.status === StatusEnum.COMPLETED
              ? ProcessStepStatus.Done
              : saleData?.isKrakenDeposited
              ? ProcessStepStatus.Pending
              : ProcessStepStatus.Inactive
            : saleData?.status === StatusEnum.COMPLETED
            ? ProcessStepStatus.Done
            : saleData?.isCryptoSold
            ? ProcessStepStatus.Pending
            : ProcessStepStatus.Inactive
        }
        pendingText={t('common:statusesList.pending') as string}
        successText={t('common:statusesList.completed') as string}
      />
    </View>
  )
}

export default StatusesList
