import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const EyeClosed: FC = () => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const G = useComponent('g')
  const Defs = useComponent('defs')
  const ClipPath = useComponent('clipPath')

  return (
    <Svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#a)">
        <Path
          d="m9.342 19.282-1.931-.518.787-2.939a10.988 10.988 0 0 1-3.237-1.872l-2.153 2.154-1.415-1.415 2.154-2.153a10.957 10.957 0 0 1-2.371-5.07l1.968-.359C3.903 11.312 7.579 14.5 12 14.5c4.42 0 8.097-3.188 8.856-7.39l1.968.358a10.958 10.958 0 0 1-2.37 5.071l2.153 2.153-1.415 1.415-2.153-2.154a10.988 10.988 0 0 1-3.237 1.872l.787 2.94-1.931.517-.788-2.94a11.07 11.07 0 0 1-3.74 0l-.788 2.94Z"
          fill="#606690"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default EyeClosed
