import React, { FC, useEffect } from 'react'
import { isIOS } from 'react-device-detect'
import { Animated, StyleSheet, Text, View } from 'react-native'
import { COLORS } from '@cryptoswift/shared/src/styles'

import { useAnimatedLabel } from '../hooks/useAnimatedLabel'
import * as controlsCss from '../styles/control'

import { SelectOption, SelectProps } from './types'

const CHANGE_FN: SelectProps['onChange'] = () => null

const ITEMS: SelectOption[] = []
const SelectWeb: FC<SelectProps> = ({
  label,
  value,
  onChange = CHANGE_FN,
  items = ITEMS,
  required = false,
  fullWidth = false,
  hint,
  error
}) => {
  const { animatedIsFocused, animatedLabelStyle } = useAnimatedLabel()

  useEffect(() => {
    Animated.timing(animatedIsFocused, {
      toValue: (value ?? '').trim() !== '' ? 1 : 0,
      duration: 200,
      useNativeDriver: false
    } as any).start()
  }, [value])

  return (
    <label>
      <View style={[styles.container, fullWidth && controlsCss.CONTROL_FULL_WIDTH]}>
        <Animated.Text style={[styles.label, value !== '' && animatedLabelStyle]}>
          {label}
          {required && <Text style={{ color: COLORS.error, marginLeft: 2, fontSize: 14 }}>*</Text>}
        </Animated.Text>
        <select
          id="currency-select"
          onChange={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { value } = e.target

            onChange(value ?? '')
          }}
          style={{
            position: 'relative',
            left: isIOS ? '-1px' : '4px',
            paddingTop: 28,
            paddingBottom: 6
          }}
          value={value}
        >
          <option value=""></option>
          {items.map(item => {
            return (
              <option value={item.value} key={item.key}>
                {item.label}
              </option>
            )
          })}
        </select>
      </View>
      {Boolean(error) && (
        <View>
          <Text style={styles.error}>{error}</Text>
        </View>
      )}
      {Boolean(hint) && (
        <View>
          <Text style={styles.hint}>{hint}</Text>
        </View>
      )}
    </label>
  )
}

const styles = StyleSheet.create({
  container: {
    ...controlsCss.CONTROL_CONTAINER
  },
  label: controlsCss.CONTROL_LABEL,
  input: controlsCss.CONTROL_INPUT,
  focusedTextInput: controlsCss.FOCUSED_BORDER_COLOR,
  error: {
    ...controlsCss.CONTROL_ERROR,
    paddingLeft: 16,
    paddingRight: 16
  },
  hint: {
    ...controlsCss.CONTROL_HINT,
    paddingLeft: 16,
    paddingRight: 16
  }
})

export default SelectWeb
