import { TextStyle, ViewStyle } from 'react-native'
import { BACKGROUNDS, COLORS, FONTS } from '@cryptoswift/shared/src/styles'

export const CONTROL_LABEL: TextStyle = {
  ...FONTS.disc,
  position: 'absolute',
  left: 16,
  top: 16,
  color: COLORS.primaryViolet
}

export const CONTROL_INPUT: TextStyle = {
  ...FONTS.disc,
  width: '100%',
  minHeight: 50,
  borderRadius: 4,
  paddingTop: 24,
  paddingBottom: 4,
  paddingLeft: 4,
  paddingRight: 4,
  borderBottomWidth: 2,
  borderBottomColor: 'transparent',
  lineHeight: 18
}

export const CONTROL_CONTAINER: ViewStyle = {
  position: 'relative',
  backgroundColor: BACKGROUNDS.bg2,
  paddingBottom: 1,
  paddingLeft: 12,
  paddingRight: 12,
  minHeight: 52,
  width: 304,
  borderRadius: 2
}

export const CONTROL_ERROR: TextStyle = {
  ...FONTS.disc,
  color: COLORS.error,
  paddingLeft: 12
}

export const CONTROL_HINT: TextStyle = {
  ...FONTS.disc,
  color: COLORS.primaryViolet,
  paddingLeft: 12
}

export const CONTROL_FULL_WIDTH = {
  width: '100%'
}

export const FOCUSED_BORDER_COLOR = {
  borderBottomColor: COLORS.primaryBlack
}
