import React, { type FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

import Button from '../../components/controls/Button'
import ExclamationErrorWarning from '../../components/icons/Utility/ExclamationErrorWarning'
import Paper from '../../components/Paper'
import { FONTS } from '../../styles'

export const KycErrorMessage: FC<{ title: string; description: string; onComplete: () => void }> = ({
  title,
  description,
  onComplete
}) => {
  const { t } = useTranslation()

  return (
    <Paper
      style={{
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        minWidth: 510,
        width: 510
      }}
      animate
      aria-label="notofication-modal"
    >
      <View>
        <View style={styles.row}>
          <ExclamationErrorWarning />
          <Text style={styles.text}>{title}</Text>
        </View>
        <Text style={styles.description}>{description}</Text>
        <View style={{ marginTop: 24 }}>
          <Button onClick={onComplete}>
            <Text>{t('common:kycErrorModal.proceed')}</Text>
          </Button>
        </View>
      </View>
    </Paper>
  )
}

export default memo(KycErrorMessage)

const styles = StyleSheet.create({
  text: {
    ...FONTS.h2,
    marginLeft: 24
  },
  row: {
    flexDirection: 'row',
    marginBottom: 24
  },
  description: {
    ...FONTS.h4
  }
})
