import React, { Component } from 'react'
import { Image, Modal, TouchableWithoutFeedback, View } from 'react-native'

import Button from '../Button'

import { styles } from './styles'

export default class ImagePreview extends Component<any> {
  render() {
    const { source, visible = false, close, imageStyle, overlayStyle } = this.props

    return (
      <Modal animationType={'fade'} transparent={true} onRequestClose={close} visible={visible}>
        <View style={[styles.overlay, overlayStyle]}>
          <TouchableWithoutFeedback onPress={close}>
            <Image resizeMode={'contain'} source={source} style={[styles.image, imageStyle]} />
          </TouchableWithoutFeedback>
        </View>
        <Button light onClick={close}>
          close
        </Button>
      </Modal>
    )
  }
}
