import { gql } from '@apollo/client'

export const RESET_PASSWORD = gql`
  mutation ResetPassword($userId: UUID!, $confirmationId: UUID!, $password: String!, $passwordConfirm: String!) {
    resetPassword(
      userId: $userId
      confirmationId: $confirmationId
      password: $password
      passwordConfirm: $passwordConfirm
    )
  }
`
