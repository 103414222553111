import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { Outlet } from 'react-router-dom'

import LogoBlue from '../../../components/icons/LogoBlue'
import { FONTS } from '../../../styles'

const WebOutlet: FC = () => {
  const { t } = useTranslation()

  return (
    <View style={{ alignItems: 'center' }} aria-label="invoice-accept">
      <View
        aria-label="header"
        style={{
          marginTop: 26,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 32,
          width: 890,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <LogoBlue />
        <Text style={{ marginLeft: 12, ...FONTS.h3 }}>{t('common:appTitle')}</Text>
      </View>

      <Outlet />
    </View>
  )
}

export default WebOutlet
