import React, { type FC, type ReactElement, type ReactNode } from 'react'
import Tippy from '@tippyjs/react'
import { type Placement } from 'tippy.js'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

interface Props {
  children?: ReactElement
  isVisible: boolean
  placement?: Placement
  content: ReactNode
  onClose: () => void
}

export const Tooltip: FC<Props> = ({ isVisible, placement, content, children, onClose }) => {
  return (
    <Tippy theme="light-border" visible={isVisible} placement={placement} content={content} onClickOutside={onClose}>
      {children}
    </Tippy>
  )
}
