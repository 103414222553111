import { gql } from '@apollo/client'

import { CURRENCY_FRAGMENT } from '../fragments/CurrencyFragment'
import { SALE_FRAGMENT, SALE_FRAGMENT_WITH_FEES } from '../fragments/SaleFragment'

export const SALE = gql`
  query Sale($uid: UUID!) {
    sale(uid: $uid) {
      sale {
        ...SaleFragment
        paymentBlockchainTransactions {
          amlStatus
          amount
          createdAt
          currency {
            ...CurrencyFragment
          }
          status
          trxId
          updatedAt
        }
      }
      error {
        errorMsg
      }
    }
  }

  ${SALE_FRAGMENT}
  ${CURRENCY_FRAGMENT}
`

export const SALE_WITH_FEES = gql`
  query SaleWithFees($uid: UUID!) {
    sale(uid: $uid) {
      sale {
        ...SaleFragmentWithFees
        crypto {
          isStablecoin
        }
      }
      error {
        errorMsg
      }
    }
  }

  ${SALE_FRAGMENT_WITH_FEES}
`
