export const getEnv = (): string => {
  try {
    const [env] = window.location.hostname
      .split('.')
      .filter(v => v !== 'app')
      .map(v => (v === 'localhost' ? 'beta' : v))

    return env
  } catch (e) {
    return 'beta'
  }
}
