import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import 'intl'
import 'intl/locale-data/jsonp/en-US'
import en from './en'

const resources = {
  // list of languages
  en
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3', //To make it work for Android devices, add this line.
    resources,
    lng: 'en', // default language to use.
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false
    }
  })
export default i18n
