import { useEffect, useRef } from 'react'
import { Animated, Easing, EasingFunction, Platform } from 'react-native'

export const useAnimatedAppear = ({ easing }: { easing?: EasingFunction } = {}) => {
  const opacity = useRef(new Animated.Value(0)).current
  const translateY = useRef(new Animated.Value(300)).current

  useEffect(() => {
    Animated.parallel([
      Animated.timing(opacity, {
        toValue: 1,
        duration: 250,
        easing,
        useNativeDriver: false
      }),
      Animated.timing(translateY, {
        toValue: 0,
        duration: 250,
        easing,
        useNativeDriver: false
      })
    ]).start()

    return () => {
      opacity.setValue(0)
      translateY.setValue(Platform.OS === 'ios' ? 200 : 300)
    }
  }, [])

  return {
    opacity: opacity,
    transform: [
      {
        translateY: translateY
      }
    ]
  }
}

export default useAnimatedAppear
