import React, { FC } from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const CheckSmall: FC<{ fill: string }> = ({ fill } = { fill: 'none' }) => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')

  return (
    <Svg width={13} height={9} fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <Path
        opacity={0.95}
        d="M1 3.544 4.456 7l6.843-6"
        stroke={fill ?? '#606690'}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </Svg>
  )
}

export default CheckSmall
