import { gql } from '@apollo/client'

import { SALE_FRAGMENT } from '../fragments/SaleFragment'

export const CANCEL_IN_PERSON_SALE = gql`
  mutation CancelInPersonSale($uid: UUID!) {
    cancelSale(uid: $uid) {
      error {
        errorMsg
      }
      sale {
        ...SaleFragment
      }
    }
  }

  ${SALE_FRAGMENT}
`
