import React, { FC, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import { StatusEnum } from '../../../__generated__/globalTypes'
import { StartInPersonSale, StartInPersonSaleVariables } from '../../../gql/mutations/__generated__/StartInPersonSale'
import { START_IN_PERSON_SALE } from '../../../gql/mutations/startInPersonSale'
import { useSale } from '../../../hooks/api/useSale'
import HedgeCheck from '../HedgeCheck'
import WebInvoiceAttentionMessage from './WebInvoiceAttentionMessage'

const WebCheckHedge: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [showAttention, setShowAttention] = useState(false)
  const { result: data, refetch: refetchSale, error: saleError } = useSale(id)
  const [startSale] = useMutation<StartInPersonSale, StartInPersonSaleVariables>(START_IN_PERSON_SALE, {
    onCompleted: () => {
      refetchSale()
    },
    onError: e => {
      console.log('startSale', e)
    }
  })

  const onCompleteHedgeCheck = () => {
    setShowAttention(true)
  }

  const onCompleteAttentionCheck = () => {
    startSale({
      variables: {
        uid: id
      }
    })
      .then(() => {
        navigate(`/invoice/${id}/process`)
      })
      .then(() => refetchSale())
  }

  if (!data) return <ActivityIndicator size="large" />

  if (data?.status === StatusEnum.ERROR || data?.status === StatusEnum.EXPIRED || !data?.cryptoCurrencyId) {
    return <Navigate to={`/invoice/${id}`} replace />
  }

  if (showAttention) {
    return <WebInvoiceAttentionMessage onComplete={onCompleteAttentionCheck} />
  }

  return (
    <HedgeCheck
      data={{
        cryptoCurrencyId: data.cryptoCurrencyId!,
        fiatCurrencyId: data.fiatCurrencyId,
        fiatCurrencyVolume: data.fiatCurrencyVolume
      }}
      onComplete={onCompleteHedgeCheck}
    />
  )
}

export default WebCheckHedge
