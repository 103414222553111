import * as React from 'react'
import { useComponent } from '@cryptoswift/shared/src/providers/Components'

const Loader = (props: Record<string, string>) => {
  const Svg = useComponent('svg')
  const Path = useComponent('path')
  const G = useComponent('g')
  const Defs = useComponent('defs')
  const ClipPath = useComponent('clipPath')

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
      <G clipPath="url(#a)">
        <Path
          fill="#797FA8"
          d="M10.5 3.622a.604.604 0 0 1-.604-.604V.604a.604.604 0 0 1 1.208 0v2.414c0 .333-.27.604-.604.604Z"
        />
        <Path
          fill="#CFD2E4"
          d="M10.5 20a.604.604 0 0 1-.604-.604v-2.414a.604.604 0 0 1 1.208 0v2.414c0 .334-.27.604-.604.604Z"
        />
        <Path
          fill="#8E95BF"
          d="M7.31 4.476a.604.604 0 0 1-.824-.22L5.28 2.163a.604.604 0 1 1 1.045-.603l1.208 2.09a.604.604 0 0 1-.221.825Z"
        />
        <Path
          fill="#E0E2F0"
          d="M15.5 18.66a.604.604 0 0 1-.825-.22l-1.207-2.092a.604.604 0 1 1 1.046-.603l1.207 2.09a.604.604 0 0 1-.221.825Z"
        />
        <Path
          fill="#8E95BF"
          d="M4.976 6.81a.604.604 0 0 1-.824.222L2.06 5.825a.604.604 0 0 1 .603-1.046l2.091 1.207a.604.604 0 0 1 .221.825Z"
        />
        <Path
          fill="#E0E2F0"
          d="M19.16 15a.604.604 0 0 1-.824.22l-2.091-1.206a.604.604 0 0 1 .603-1.046l2.091 1.207a.604.604 0 0 1 .221.825Z"
        />
        <Path
          fill="#8E95BF"
          d="M4.122 10c0 .333-.27.604-.604.604H1.104a.604.604 0 1 1 0-1.208h2.414c.333 0 .604.27.604.604Z"
        />
        <Path
          fill="#E0E2F0"
          d="M20.5 10c0 .333-.27.604-.604.604h-2.414a.604.604 0 1 1 0-1.208h2.414c.334 0 .604.27.604.604Z"
        />
        <Path
          fill="#CFD2E4"
          d="M4.976 13.19a.604.604 0 0 1-.22.824L2.663 15.22a.604.604 0 1 1-.603-1.046l2.09-1.207a.604.604 0 0 1 .825.221ZM7.31 15.524a.604.604 0 0 1 .222.824L6.324 18.44a.604.604 0 0 1-1.045-.603l1.207-2.091a.604.604 0 0 1 .825-.221Z"
        />
        <Path
          fill="#656C96"
          d="M15.5 1.34a.604.604 0 0 1 .22.824l-1.206 2.091a.604.604 0 0 1-1.046-.603l1.207-2.091a.604.604 0 0 1 .825-.221Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M.5 0h20v20H.5z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
export default Loader
