import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useSearchParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import Button from '../../components/controls/Button'
import LogoBlue from '../../components/icons/LogoBlue'
import CheckSuccess from '../../components/icons/Utility/CheckSuccess'
import ExclamationError from '../../components/icons/Utility/ExclamationError'
import Paper from '../../components/Paper'
import { ConfirmEmail, ConfirmEmailVariables } from '../../gql/mutations/__generated__/ConfirmEmail'
import { CONFIRM_EMAIL } from '../../gql/mutations/confirmEmail'
import { FONTS } from '../../styles'

const EmailConfirm: FC = () => {
  const [params] = useSearchParams()
  const [done, setDone] = useState<boolean | null>(null)
  const [errorApi, setError] = useState('')
  const [verify] = useMutation<ConfirmEmail, ConfirmEmailVariables>(CONFIRM_EMAIL)

  useEffect(() => {
    const [userId, uuid] = [params.get('userId'), params.get('uuid')]

    if (userId && uuid) {
      verify({ variables: { userId, confirmationId: uuid } })
        .then(() => {
          setDone(true)
          setError('')
        })
        .catch(e => {
          setDone(false)
          setError(e?.graphQLErrors?.[0]?.message)
        })
    }
  }, [])

  const { t } = useTranslation()

  return (
    <View>
      <View
        aria-label="full-page"
        style={{
          marginTop: 26,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 32,
          width: 564,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <LogoBlue />
        <Text style={{ marginLeft: 12, ...FONTS.h3 }}>{t('common:appTitle')}</Text>
      </View>
      <Paper
        aria-label="full-page-paper"
        style={{
          paddingBottom: 32,
          paddingLeft: 32,
          paddingTop: 32,
          paddingRight: 32,
          minWidth: 564,
          width: 564
        }}
      >
        <View>
          <View style={styles.row}>
            {done && (
              <>
                <CheckSuccess />
                <Text style={styles.text}>{t('common:emailConfirmWeb.emailVerified')}</Text>
              </>
            )}
            {errorApi && (
              <>
                <ExclamationError />
                <Text style={styles.text}>{t('common:emailConfirmWeb.emailVerificationError')}</Text>
              </>
            )}
          </View>
          <View>
            <Text style={styles.description}>
              {done && t('common:emailConfirmWeb.congrats')}
              {!done && errorApi}
            </Text>
          </View>
          {(
            <View style={{ marginTop: 24 }}>
              <Button
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.open('cryptoswift://home')
                }}
              >
                {t('common:emailConfirmWeb.goToApp')}
              </Button>
            </View>
          ) && done}
        </View>
      </Paper>
    </View>
  )
}

export default EmailConfirm

const styles = StyleSheet.create({
  text: {
    ...FONTS.h2,
    marginLeft: 24
  },
  description: {
    ...FONTS.h4
  },
  row: {
    flexDirection: 'row',
    marginBottom: 24
  }
})
