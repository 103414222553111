export default {
  appTitle: 'CryptoSwift',
  back: 'Back',
  needHelp: 'Feedback',
  poweredBy: 'Powered by:',
  paymentType: {
    SALE: 'In person sale',
    INVOICE: 'Invoice'
  },
  close: 'Close',
  forms: {
    email: 'Email',
    password: 'Password',
    createPassword: 'Create Password',
    repeatPassword: 'Repeat Password',
    cryptoCurrency: 'Crypto currency',
    description: 'Description',
    sendReceiptOptional: 'Send receipt to (Optional)',
    price: 'PRICE',
    priceAndDate: 'PRICE AND DATE OF EXPIRATION',
    attachPhoto: 'Attach Photo',
    optional: 'Optional',
    detailedInformation: 'DETAILED INFORMATION',
    customersInformation: "CUSTOMER'S INFORMATION",
    invoiceExpires: 'Invoice expires',
    sendInvoiceTo: 'Send invoice to',
    phoneNumberOptional: 'Phone number (Optional)',
    companyName: 'Company name',
    inPersonSaleFormTitle: 'Enter sale details',
    sendInvoiceFormTitle: 'Enter invoice details',
    createNewPassword: 'Create a new password',
    repeatNewPassword: 'Repeat new password',
    companyEmail: 'Company email',
    defaultCurrency: 'Default currency',
    filters: 'Filters',
    date: 'Date',
    search: 'Search',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    selectDocument: 'Select Document',
    clientName: "Customer's name",
    clientNameRequired: "Customer's name is a required field",
    companyNameRequired: 'Company name is a required field',
    pleaseSelectCurrency: 'Please select currency',
    required: 'This field is required',
    emailRequired: 'Email is a required field',
    passwordTooShort: 'Password is too short - should be 8 chars minimum.',
    passwordRequired: 'Password is a required field',
    documentTypeIsRequired: 'Please select a document type',
    indicativePriceInCryptocurrency: 'Indicative price in cryptocurrency',
    passwordMustContain1Lower: 'Password must contain at least 1 lower case letter',
    passwordMustContain1Upper: 'Password must contain at least 1 upper case letter',
    passwordMustContainNumber: 'Password must contain at least 1 number',
    passwordMustContainSpecialChar: 'Password must contain at least 1 special character',
    passwordNotMatch: 'Passwords must match',
    currentPasswordIsRequiredField: 'Current Password is a required field',
    emailMustBeValid: 'Email must be valid',
    descriptionFieldIsRequired: 'Description is a required field',
    priceIsRequired: 'Price is a required field',
    cryptoCurrencyRequired: 'Crypto currency is a required field',
    invoiceExpiredRequired: 'Invoice expires is a required field'
  },
  general: {
    priceIn: 'Price in',
    indicativePrice: 'Indicative price',
    symbols: 'symbols',
    errors: {
      invalidPassword: 'Current password is invalid'
    },
    maxMinValues: 'Minimum - {{minimum}}, maximum - {{maximum}}'
  },
  fileUpload: {
    uploadLogo: 'Upload logo',
    takePicture: 'Take picture',
    selectFromGallery: 'Select from gallery',
    cancel: 'Cancel'
  },
  signUp: {
    welcome: 'Welcome to CryptoSwift!',
    pleaseSignUp: 'Please sign up.',
    submit: 'Sign Up',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign in!'
  },
  signIn: {
    welcome: 'Welcome back to CryptoSwift!',
    pleaseSignIn: 'Please sign in.',
    submit: 'Sign In',
    dontHaveAccount: "Don't have an account?",
    signUp: 'Sign up!'
  },
  forgotPassword: {
    general: 'Forgot your password?',
    emailVerificationTitle: 'Please enter your email for verification.',
    getLink: 'get a link for a new password'
  },
  emailResetMessageSent: {
    title: 'Check your email!',
    message: {
      infoText:
        "Please check your inbox for a <b>{{resetLink}}</b> we've sent to your email address. <b>{{clickOnLink}}</b> to reset password.",
      resetLink: 'reset link',
      clickOnLink: 'Click on the link'
    },
    didntReceive: "Didn't receive it?",
    resetAgain: 'Try to reset password again.',
    goToSignIn: 'Go to sign in'
  },
  emailConfirm: {
    title: 'Check your email!',
    message: {
      infoText:
        "Please check your inbox for a <b>verification link</b> we've sent to your email address. <b>Click on the link</b> to complete the verification process.",
      verificationLink: 'reset link',
      clickOnLink: 'Click on the link'
    },
    didntReceive: "Didn't receive it?",
    resend: 'Resend Verification Email.',
    goToSignIn: 'Go to sign in'
  },
  terms: {
    title: 'Terms Of Service',
    subtitle: 'Before you sign up, please make sure you agree to our Terms of Service and Privacy Policy.',
    agreeTerms: 'I agree to CryptoSwift Terms of Service.',
    agreeInfoShare:
      'I agree to processing my information as described above and further explained in the Privacy Policy.',
    accept: 'Accept',
    download: 'DOWNLOAD'
  },
  createSale: {
    newSale: 'New in person sale',
    paymentMethod: 'Choose payment method',
    totalPayment: 'Total payment',
    makeSale: 'Make in person sale',
    sendInvoice: 'Send Invoice',
    totalPaymentByCustomer: 'Total payment by Customer',
    tooltipText: 'The payment process will begin immediately \n after the sale is created.'
  },
  settings: {
    title: 'Settings',
    changesSaved: 'Changes saved',
    saveChanges: 'Save Changes',
    companyInformation: 'Company information',
    securitySettings: 'Security settings',
    changePassword: 'Change password',
    linkSent: 'A reset link has been sent to your email.',
    changeEmail: 'Change email',
    sendConfirmationEmail: 'Send confirmation email',
    paymentDetails: 'Payment details',
    emailResetDone: 'Check new email for instructions.'
  },
  help: {
    title: 'Help',
    subtitle: 'If you have a question, please search through F.A.Q. or contact support.',
    askSupport: "I don't see the answer to my question, contact support",
    historySearch: 'Search history',
    historySearchSubtitle: 'See articles you were researching before',
    myQuestions: 'My Questions',
    noSearchResults: 'Sorry, no results found'
  },
  salesList: {
    title: 'Sales',
    listIsEmpty: 'No added sales yet',
    pendingListIsEmpty: 'No sales with pending status',
    subtitle: 'See information about all historical transactions',
    tableHeaders: {
      saleId: 'Sale ID',
      fiat: 'Fiat',
      crypto: 'Crypto',
      created: 'Created',
      description: 'Description',
      paymentMethod: 'Payment \nmethod',
      status: 'Status',
      customer: 'Customer'
    },
    filterBy: {
      title: 'Filter by',
      crypto: 'Crypto',
      paymentMethod: 'Payment method',
      status: 'Status',
      setFilters: 'Set filters',
      inPersonSale: 'In person sale',
      invoice: 'Invoice'
    }
  },
  salesDetails: {
    title: 'Details',
    cardTitle: 'Details',
    saleId: 'Sale Id',
    paymentMethod: 'Payment method',
    fullName: 'Full Name',
    receiptSentTo: 'Receipt sent to',
    dateCreated: 'Date Created',
    datePaid: 'Date Paid',
    fiat: 'Fiat',
    crypto: 'Crypto',
    description: 'Description',
    photos: 'Photos',
    transactions: 'Transactions',
    transactionId: 'Transaction ID',
    fiatAmount: 'Fiat Amount',
    cryptoAmount: 'Crypto Amount',
    dateTime: 'Date & Time',
    status: 'Status',
    blockchainFee: 'Blockchain Fee',
    depositFee: 'Deposit Fee',
    hedgingFee: 'Hedging Fee',
    withdrawalFee: 'Withdrawal Fee',
    bankInternalFee: 'Bank internal fee',
    krakenTradesFee: 'Kraken Trades Fee'
  },
  createCompany: {
    companyInfo: 'Company info',
    setUpAndStart: 'Set UP and start',
    tooltipText: 'This information will be displayed to clients.'
  },
  invoiceSent: {
    invoiceSent: 'Invoice sent',
    invoiceSentError: 'Invoice not sent',
    sending: 'Sending invoice',
    detailedInformation: 'Detailed information',
    saleId: 'Sale ID',
    fiat: 'Fiat',
    crypto: 'Crypto',
    created: 'Created',
    description: 'Description',
    paymentMethod: 'Payment method',
    dueDate: 'Expiry date',
    customer: 'Customer',
    priceIn: 'Price in',
    clientName: "Customer's name",
    sentTo: 'Receipt sent to',
    details: 'DETAILS',
    copyLink: 'COPY INVOICE LINK',
    retry: 'Try again',
    phoneNumber: 'Phone number'
  },
  checkout: {
    showPayment: 'To payment',
    underpaidModalTitle: 'Insufficient crypto received',
    underpaidModalDescription:
      'You have received less crypto than requested from your customer. You can request the customer to top-up by clicking “Continue” You can cancel the transaction. (but this will require a refund of crypto received - please contact CryptoSwift support).',
    overpaidModalTitle: 'Overpaid',
    overpaidModalDescription:
      "You've overpaid by <b>{{diff}} {{cryptoCurrency}}</b>. To return the excess funds, please contact us at <b>{{email}}</b> with your transaction details.",
    confirmPerson: 'Confirm',
    createSaleError: 'Error',
    showLess: 'Hide Details',
    showMore: 'Show Details',
    phone: 'Phone number',
    cancelled: 'Transaction stopped',
    cancel: 'Cancel',
    generateAnotherCode: 'Generate another QR code',
    returnToDashboard: 'Return To Dashboard',
    returnToHome: 'Go Home',
    paymentConfirmed: 'Payment Confirmed',
    scanQrCode: 'Scan QR code',
    expired: 'Expired',
    expiresIn: 'Expires in',
    useAddressBelow: 'Or use the address below',
    address: 'Address',
    cryptoCurrency: 'Crypto currency',
    currentStatus: 'Current Status',
    saleExpired: 'SALE Expired',
    transactionStopped: 'Transaction stopped',
    saleInformation: 'Sale information',
    saleId: 'Sale ID',
    dateTime: 'Date & Time',
    receiptSentTo: 'Receipt sent to',
    description: 'Description',
    clientName: "Customer's name",
    totalPayment: 'Total'
  },
  saleSummary: {
    customersDetails: "Customer's Details",
    clientName: "Customer's name",
    receiptSentTo: 'Receipt sent to',
    costOfGoodsService: 'Cost of Good/Service',
    cryptoChosenForPayment: 'Crypto currency chosen for payment',
    amount: 'Amount',
    purchaseGoodService: 'Purchased Good/Service',
    description: 'Description',
    totalForPaymentByCustomer: 'Total for payment by Customer',
    proceedToPayment: 'PROCEED TO PAYMENT',
    kycCompleted: 'Kyc completed',
    transactionSummary: 'Transaction Summary',
    photos: 'Photos'
  },
  emailConfirmWeb: {
    emailVerified: 'The email has been verified!',
    emailVerificationError: 'Email verification error',
    congrats: 'Congratulations! Your email has been successfully verified. You can now start using our service.',
    goToApp: 'Go to app'
  },
  invoiceAccept: {
    getSaleError: 'Error',
    qrExpired: 'PAYMENT EXPIRED',
    saleSummary: 'Transaction summary',
    invoiceExpired: 'Invoice Expired',
    dueDate: 'Expiry date',
    detailedInformation: 'Detailed information',
    saleId: 'Invoice ID',
    priceIn: 'Price in',
    clientName: "Customer's name",
    receiptSentTo: 'Receipt sent to',
    details: 'DETAILS',
    description: 'Description',
    selectDigitalCurrency: 'Select digital currency',
    total: 'Total',
    proceedToPayment: 'PROCEED TO PAYMENT',
    invoiceExpiredError: 'Invoice expired error'
  },
  saleAttention: {
    attention: 'Attention!',
    message:
      'Your customer will now be asked to scan a QR code to make the payment. Please make sure the customer is ready before proceeding.',
    message_invoice:
      'Now you will be asked to scan a QR code to make the payment. Please make sure that you is ready before proceeding.',
    proceed: 'PROCEED'
  },
  hedgingAttention: {
    continue: 'CONTINUE',
    attention: 'Hedge module is unavailable',
    message:
      'Due to market conditions, hedging of crypto currencies is unavailable. You may choose to proceed with this sale and CryptoSwift will transfer the fiat proceeds obtained from the sale of the received crypto. Alternatively, you may Cancel the transaction',
    proceed: 'PROCEED'
  },
  verificationSuccessMessage: {
    title: 'Verification completed!'
  },
  paymentProcess: {
    makeSureCameraEnabled: 'Please make sure the camera is enabled \n and permissions are granted',
    confirmPerson: 'Confirm, that document and client in front of you are same person',
    kycRulesAccept: 'By submitting I confirm that the customer is pictured in the provided id',
    kyc: {
      continue: 'CONTINUE',
      docs: {
        ID: 'Id',
        IBAN: 'Iban',
        CHEQUE: 'Cheque',
        TAX_SHEET: 'Tax Sheet',
        PAY_SLIP: 'Pay Slip',
        ADDRESS_PROOF: 'Address Proof',
        CREDIT_CARD: 'Credit card',
        PORTRAIT: 'Portrait',
        LEGAL_ENTITY: 'Legal Entity',
        CAR_REGISTRATION: 'Car Registration',
        LIVENESS: 'Liveness'
      },
      sale: {
        title: 'KYC Verification',
        subtitle: 'Have you KYC-ed this customer before or would you like to go through KYC process now?',
        verifyText: 'This is a new customer, proceed to KYC',
        skipText: 'I’ve KYC-ed this customer before, no need to KYC',
        verifyDescription:
          'Customer will need to scan their id document and verify their likeliness before being able to pay.',
        skipDescription: 'Customer can skip the document scanning step and proceed directly to payment.'
      },
      invoice: {
        title: 'KYC Verification',
        subtitle: 'Have you KYC-ed this customer before or would you like the customer to go through KYC process?',
        verifyText: 'This is a new customer, require KYC',
        skipText: 'I’ve KYC-ed this customer before, no need to KYC',
        verifyDescription: 'Customer will not need to scan document before proceeding to invoice payment.',
        skipDescription:
          'Customer will need to scan their id document and verify their likeliness before being able to pay.'
      }
    },
    makePhotoOfDocument: 'Make a photo of the document',
    proceedWithTransaction: 'PROCEED WITH TRANSACTION',
    kycVerification: 'KYC Verification',
    kycVerificationScan: 'Make photo',
    kycVerificationSubmit: 'Submit',
    kycVerificationScanNew: 'Make a new photo',
    checkScanQuality: 'CHECK Scan Quality',
    idSeenClearly: 'Please make sure your ID can be seen clearly.',
    scanAgainOrSubmit: 'Scan again or submit.',
    kycVerificationChecking: 'Checking...'
  },
  resetPassword: {
    resetPassword: 'Reset password',
    resetPasswordBelow: 'Please reset your password below.',
    confirm: 'Confirm',
    passwordChanged: 'Password was successfully changed',
    goToApp: 'Go to app'
  },
  dashboard: {
    analytics: 'Analytics',
    timePeriodForAnalytics: 'Time period for analytics',
    incomeForThisPeriod: 'Income for this period',
    settled: 'Settled',
    pendingSettlement: 'Pending settlement',
    sentInvoices: 'Sent invoices',
    incomeChart: 'Income chart',
    incomeChartEmpty: 'No completed sales in the selected period',
    incomeFor: 'Income for',
    pendingPayments: 'Pending payments',
    pendingPaymentsAndInvoices: 'See your pending payments & invoices'
  },
  textCollapse: {
    showMore: 'Show More',
    showLess: 'Show Less'
  },
  picker: {
    selectCustomDate: 'Select Custom Date',
    selectRange: 'Select Range'
  },
  saleApiErrors: {
    RISKY: 'Sale stopped. Your payment has been marked as risky. Please contact support.',
    DROPPED: 'Sale stopped. An error has occurred in the blockchain. Please contact support.',
    HEDGING_ERROR: 'Sale stopped. An error occurred during hedging. Please contact support.',
    AML_CHECK_ERROR: 'Sale stopped. An error occurred during the AML check. Please contact support.',
    DEPOSIT_KRAKEN_ERROR: 'Sale stopped. An error occurred while transferring funds to Kraken. Please contact support.',
    SWAP_KRAKEN_ERROR:
      'Sale stopped. An error occurred while selling cryptocurrency on Kraken. Please contact support.',
    WITHDRAWAL_KRAKEN_ERROR:
      'Sale stopped. An error occurred while withdrawing funds from Kraken. Please contact support.',
    P3_INTERNAL_ERROR:
      'Sale stopped. An error occurred while transferring funds to the bank account. Please contact support.'
  },
  saleExpireErrors: {
    PAYMENT_EXPIRED: 'Payment expired',
    INVOICE_EXPIRED: 'Invoice expired'
  },
  kycErrorModal: {
    title_general: 'Document validation',
    title_nameMismatch: 'Name mismatch',
    proceed: 'Try again',
    error: {
      nameMismatch_merchant: 'Try take the photo again or check that the information you entered is correct.',
      nameMismatch_client: 'Try take the photo again or ask your merchant to create an invoice with the correct name.',
      unexpected: 'An unexpected error occurred. Our team is already working on the issue, please try again later.'
    }
  },
  saleInvalidAmountModal: {
    title: 'Error',
    proceed: 'PROCEED',
    description: 'The amount you tried to enter is more than allowed. Try entering a different amount.'
  },
  statusesList: {
    cryptoDetected: 'CRYPTO DETECTED',
    amlCheck: 'AML Check',
    hedging: 'HEDGING {{cryptoName}}',
    cryptoSold: 'CRYPTO SOLD',
    selling: 'Selling {{cryptoName}}',
    waitingFor: 'Waiting for {{cryptoName}}',
    received: '{{cryptoName}} received',
    movingCrypto: 'MOVING CRYPTO',
    sentToYourBank: 'SENT TO YOUR BANK',
    pending: 'Pending',
    completed: 'Completed',
    cryptoDetectedTooltip: 'Crypto payment by your Customer has been detected by CryptoSwift on the blockchain.',
    amlCheckTooltip:
      'Anti Money-Laundering check uses best-in-class crypto analytics to ensure that crypto you receive comes from legitimate wallets.',
    hedgingTooltip: 'CryptoSwift is hedging your crypto market risk using its bespoke hedging algorithms.',
    cryptoSoldTooltip:
      'CryptoSwift is carefully selling the crypto you received in the market, using its algorithmic trading engine.',
    movingCryptoTooltip:
      'Crypto sent by Customer is being moved a safe account before your fiat funds are released to your bank account.'
  }
}
