import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  image: {
    marginHorizontal: '12%',
    marginVertical: '12%',
    flex: 1
  },
  button: {
    position: 'absolute',
    top: 40,
    right: 20
  }
})
