import { gql } from '@apollo/client'

export const FIAT_IN_CRYPTO = gql`
  query FiatInCrypto($data: FiatCryptoInput!) {
    indicativePrice(data: $data) {
      error {
        errorMsg
      }
      amount
      rate
    }
  }
`
