import { gql } from '@apollo/client'

import { SALE_FRAGMENT } from '../fragments/SaleFragment'

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($data: UpdateInvoiceInput!, $uid: UUID!) {
    updateInvoice(data: $data, uid: $uid) {
      sale {
        ...SaleFragment
      }
      error {
        errorMsg
      }
    }
  }

  ${SALE_FRAGMENT}
`
